import React, { useCallback, memo } from 'react';
import { motion} from 'framer-motion';
import { ReactComponent as Legal } from './Legal.svg';
import './LegalMain.css';
const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  
  const transition = {
    duration: 2,
    yoyo: Infinity, 
  };
  
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.4,
      },
    },
  };
  const flipUnitVariants = {
    hidden: {
      opacity: 0,
      y: 20, 
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };
  
  const AnimatedCard = memo(({ animation, digit }) => {
    return(
      <div className={`flipCard ${animation}`}>
        <span>{digit}</span>
      </div>
    );
  });
  
  const StaticCard = memo(({ position, digit }) => {
    return(
      <div className={position}>
        <span>{digit}</span>
      </div>
    );
  });
  
  const FlipUnitContainer = memo(({ digit, shuffle }) => {
    let currentDigit = digit;
    let previousDigit = digit - 1;
  
    if ( previousDigit < 0 ) 
      previousDigit = 0;
  
    const digit1 = shuffle ? previousDigit : currentDigit;
    const digit2 = !shuffle ? previousDigit : currentDigit;
  
    const animation1 = shuffle ? 'fold' : 'unfold';
    const animation2 = !shuffle ? 'fold' : 'unfold';
  
    return(
      <div className={'flipUnitContainer'}>
        <StaticCard 
          position={'upperCard'} 
          digit={currentDigit} 
          />
        <StaticCard 
          position={'lowerCard'} 
          digit={previousDigit} 
          />
        <AnimatedCard 
          digit={digit1}
          animation={animation1}
          />
        <AnimatedCard 
          digit={digit2}
          animation={animation2}
          />
      </div>
    );
  });///////////////////
export const LegalMain = () =>{
        

  const EXPERIENCE_START = 2;
  const EXPERIENCE_END = 8; 
  const COMPANIES_START = 40;
  const COMPANIES_END = 46; 
  const CLIENTS_START = 294;
  const CLIENTS_END = 300;


  const [experience, setExperience] = React.useState(EXPERIENCE_START);
  const [experienceShuffle, setExperienceShuffle] = React.useState(false);

  const [companies, setCompanies] = React.useState(COMPANIES_START);
  const [companiesShuffle, setCompaniesShuffle] = React.useState(false);

  const [clients, setClients] = React.useState(CLIENTS_START);
  const [clientsShuffle, setClientsShuffle] = React.useState(false);

  const incrementExperience = useCallback(() => {
    if (experience < EXPERIENCE_END) {
      setExperienceShuffle(!experienceShuffle);
      return experience + 1;
    }
    return experience;
  }, [experience, experienceShuffle]);

  const incrementCompanies = useCallback(() => {
    if (companies < COMPANIES_END) {
      setCompaniesShuffle(!companiesShuffle);
      return companies + 1;
    }
    return companies;
  }, [companies, companiesShuffle]);

  const incrementClients = useCallback(() => {
    if (clients < CLIENTS_END) {
      setClientsShuffle(!clientsShuffle);
      return clients + 1;
    }
    return clients;
  }, [clients, clientsShuffle]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setExperience(incrementExperience);
      setCompanies(incrementCompanies);
      setClients(incrementClients);
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, [incrementExperience, incrementCompanies, incrementClients]);
  const handleCallButtonClick = () => {
    window.location.href = "tel:+79788536025";
  };


  return (
    <div className="MainHR-container">
        <div>
        <motion.h1 
        className='in-text'
        variants={variants}
        initial='hidden'
        animate='visible'
        transition={transition}
      >
        ЮРИДИЧЕСКИЕ УСЛУГИ КОНСУЛЬТАЦИЯ 
      </motion.h1>
      <motion.p
        className='sub-text'
        variants={variants}
        initial='hidden'
        animate='visible'
        transition={{...transition, delay: 0.5}}
      >
       Мы поможем вам разобраться в юридических вопросах и защитить ваши права
      </motion.p>
      <div className='line'>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 80">
  <path fill="none" stroke="rgb(182, 182, 182)"  d="M0,50 Q240,10 480,50 Q720,90 960,50 Q1200,10 1440,50"></path>
</svg>
      </div>
      <motion.div
  className='opit'
  variants={containerVariants}
  initial='hidden'
  animate='visible'
>
  <motion.div className='module' variants={flipUnitVariants}>
    <motion.div className='number'>
      <FlipUnitContainer digit={experience} shuffle={experienceShuffle} />
    </motion.div>
    <div className='text'>более лет опыта</div>
  </motion.div>
  <motion.div className='module' variants={flipUnitVariants}>
    <motion.div className='number'>
      <FlipUnitContainer digit={companies} shuffle={companiesShuffle} />
    </motion.div>
    <div className='text'>более дел</div>
  </motion.div>
  <motion.div className='module' variants={flipUnitVariants}>
    <motion.div className='number'>
      <FlipUnitContainer digit={clients} shuffle={clientsShuffle} />
    </motion.div>
    <div className='text'>более довольных клиентов</div>
  </motion.div>
</motion.div>
        </div>
        <div className='img-container'>
   <Legal/>

    <motion.div
    className='sc'
    variants={variants}
    initial='hidden'
    animate='visible'
    transition={transition}
  >
    <motion.button
      className="roun-button"
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 1.9 }}
      onClick={handleCallButtonClick}
    >
      Получить консультацию
    </motion.button>
    <div className="wave"></div>
  </motion.div>
        </div>
      
    </div>
  );
}