import {useEffect} from 'react';
import {Perspectives} from './Perspectives/Perspectives';
import { MainHR } from './MainHR/MainHR';
import { ServicesHR } from './ServicesHR/ServicesHR';
import { PriceHr } from './PriceHr/PriceHr';
import { Contacts } from './Сontacts/Сontacts';
import { ScrollToTopButton } from '../ScrollToTopButton/ScrollToTopButton';


export const Main = () => {
  useEffect(() => {
    // Подключаем скрипт здесь
    window.YandexRotorSettings = {
      WaiterEnabled: true,
      FailOnTimeout: false,
      NoJsRedirectsToMain: true
    };
    // Здесь можно выполнить дополнительные действия при монтировании компонента, если это необходимо
  }, []); // Пустой массив зависимостей означает, что эффект будет выполнен один раз после монтирования
  return (
    <div className="main-container">
  <MainHR/>
  <Perspectives/>
  <ServicesHR/>
  <PriceHr/>
  <Contacts/>
  <ScrollToTopButton />
    </div>
  );
};

