import React, { useCallback, memo } from 'react';
import  './MainSfera.css';
import { motion } from 'framer-motion';

const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  
  const transition = {
    duration: 2,
    yoyo: Infinity, 
  };
  const AnimatedCard = memo(({ animation, digit }) => {
    return(
      <div className={`flipCard ${animation}`}>
        <span>{digit}</span>
      </div>
    );
  });
  
  const StaticCard = memo(({ position, digit }) => {
    return(
      <div className={position}>
        <span>{digit}</span>
      </div>
    );
  });
  
  const FlipUnitContainer = memo(({ digit, shuffle }) => {
    let currentDigit = digit;
    let previousDigit = digit - 1;
  
    if ( previousDigit < 0 ) 
      previousDigit = 0;
  
    const digit1 = shuffle ? previousDigit : currentDigit;
    const digit2 = !shuffle ? previousDigit : currentDigit;
  
    const animation1 = shuffle ? 'fold' : 'unfold';
    const animation2 = !shuffle ? 'fold' : 'unfold';

  
    return(
      <div className={'flipUnitContainer'}>
        <StaticCard 
          position={'upperCard'} 
          digit={currentDigit} 
          />
        <StaticCard 
          position={'lowerCard'} 
          digit={previousDigit} 
          />
        <AnimatedCard 
          digit={digit1}
          animation={animation1}
          />
        <AnimatedCard 
          digit={digit2}
          animation={animation2}
          />
      </div>
    );
  });///////////////////
  
export const MainSfera = () => {
    const containerVariants = {
        hidden: {},
        visible: {
          transition: {
            staggerChildren: 0.4,
          },
        },
      };
      const flipUnitVariants = {
        hidden: {
          opacity: 0,
          y: 20, 
        },
        visible: {
          opacity: 1,
          y: 0,
        },
      };
      const EXPERIENCE_START = 4;
      const EXPERIENCE_END = 9; 
      const COMPANIES_START = 61;
      const COMPANIES_END = 66; 
      const CLIENTS_START = 2995;
      const CLIENTS_END = 3000;
    
    
      const [experience, setExperience] = React.useState(EXPERIENCE_START);
      const [experienceShuffle, setExperienceShuffle] = React.useState(false);
    
      const [companies, setCompanies] = React.useState(COMPANIES_START);
      const [companiesShuffle, setCompaniesShuffle] = React.useState(false);
    
      const [clients, setClients] = React.useState(CLIENTS_START);
      const [clientsShuffle, setClientsShuffle] = React.useState(false);
    
      const incrementExperience = useCallback(() => {
        if (experience < EXPERIENCE_END) {
          setExperienceShuffle(!experienceShuffle);
          return experience + 1;
        }
        return experience;
      }, [experience, experienceShuffle]);
    
      const incrementCompanies = useCallback(() => {
        if (companies < COMPANIES_END) {
          setCompaniesShuffle(!companiesShuffle);
          return companies + 1;
        }
        return companies;
      }, [companies, companiesShuffle]);
    
      const incrementClients = useCallback(() => {
        if (clients < CLIENTS_END) {
          setClientsShuffle(!clientsShuffle);
          return clients + 1;
        }
        return clients;
      }, [clients, clientsShuffle]);
    
      React.useEffect(() => {
        const timer = setInterval(() => {
          setExperience(incrementExperience);
          setCompanies(incrementCompanies);
          setClients(incrementClients);
        }, 800);
    
        return () => {
          clearInterval(timer);
        };
      }, [incrementExperience, incrementCompanies, incrementClients]);
      const handleCallButtonClick = () => {
        window.location.href = "tel:+79787902506";
      };
    
    
    return (
        <div className='sfera-conteiner'>
            <div>
        <motion.h1 
        className='main-textt'
        variants={variants}
        initial='hidden'
        animate='visible'
        transition={transition}
      >
       УЧЕБНЫЙ ЦЕНТР «СФЕРА ОХРАНЫ ТРУДА»
      </motion.h1>
      <motion.p
        className='sub-text'
        variants={variants}
        initial='hidden'
        animate='visible'
        transition={{...transition, delay: 0.5}}
      >
       С нами стать профессионалом - просто 
      </motion.p>
      <div className='line'>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 80">
  <path fill="none" stroke="rgb(182, 182, 182)"  d="M0,50 Q240,10 480,50 Q720,90 960,50 Q1200,10 1440,50"></path>
</svg>
      </div>
      <motion.div
  className='opit'
  variants={containerVariants}
  initial='hidden'
  animate='visible'
>
  <motion.div className='module' variants={flipUnitVariants}>
    <motion.div className='number'>
      <FlipUnitContainer digit={experience} shuffle={experienceShuffle} />
    </motion.div>
    <div className='text'>более лет опыта</div>
  </motion.div>
  <motion.div className='module' variants={flipUnitVariants}>
    <motion.div className='number'>
      <FlipUnitContainer digit={companies} shuffle={companiesShuffle} />
    </motion.div>
    <div className='text'>более предприятий</div>
  </motion.div>
  <motion.div className='module' variants={flipUnitVariants}>
    <motion.div className='number'>
      <FlipUnitContainer digit={clients} shuffle={clientsShuffle} />
    </motion.div>
    <div className='text'>более довольных клиентов</div>
  </motion.div>
</motion.div>
        </div>
        <div className='img-cont'>
        <svg width="561.54772" height="600.42312" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 806.63176 700.76576">

<g>
 <title>background</title>
 <rect fill="none" id="canvas_background" height="402" width="582" y="-1" x="-1"/>
</g>
<g>
 <title>Layer 1</title>
 <path id="svg_1" fill="#3f3d56" d="m273.647678,266.848357l81.43311,0a1,1 0 0 0 0,-2l-81.43311,0a1,1 0 0 0 0,2z"/>
 <path id="svg_2" fill="#ffb6b6" d="m310.163798,174.670047a7.74427,7.74427 0 0 0 -3.48552,10.22377a7.42858,7.42858 0 0 0 0.63322,1.05238l-15.33253,22.24522l8.55036,11.75148l17.709,-32.744a7.72281,7.72281 0 0 0 1.98883,-9.21623a7.3907,7.3907 0 0 0 -9.88217,-3.40406q-0.0912,0.04447 -0.18119,0.09144z"/>
 <path id="svg_3" fill="#b2c6c5" d="m220.204868,176.153987l0,0a13.37258,13.37258 0 0 0 -3.88835,19.86063l19.03209,24.27643l42.13954,31.149s29.67783,-25.92733 29.91566,-33.98608s-2.93811,-9.76563 0.23782,-8.05875s6.06774,0.42724 4.97753,-0.47355s-0.70564,-1.00066 -0.04449,-2.5387s2.9847,-8.7907 2.9847,-8.7907l-11.57268,-9.44641s-10.802,6.55251 -11.51336,9.63373s3.17594,1.70688 -0.71137,3.08123s-9.63622,-2.63463 -5.03173,1.71655s4.45878,6.44526 0.64432,6.77257s-9.83589,10.94532 -9.83589,10.94532l-41.158,-41.90195a13.37259,13.37259 0 0 0 -16.17579,-2.23932z"/>
 <path id="svg_4" fill="#f2f2f2" d="m216.068948,635.450727l-126.37793,0a9.82225,9.82225 0 0 1 -9.811,-9.81055l0,-106.37891a9.82224,9.82224 0 0 1 9.811,-9.81054l126.37793,0a9.82223,9.82223 0 0 1 9.811,9.81054l0,106.37891a9.82224,9.82224 0 0 1 -9.811,9.81055z"/>
 <polygon id="svg_5" fill="#ffb6b6" points="22.874 487.734 22.824 498.75 66.241 504.189 66.314 487.929 22.874 487.734"/>
 <path id="svg_6" fill="#2f2e41" d="m27.990548,484.881527l1.01461,18.40311l-7.88255,-0.03578l-12.15974,17.43678a4.9238,4.9238 0 0 1 -8.96283,-2.83857l0.09942,-21.90366l7.81419,-3.74l-7.79036,-1.5095l0.03748,-8.25856l27.82978,2.44618z"/>
 <path id="svg_7" fill="#e6e6e6" d="m385.453258,258.122977c-26.6619,-6.63657 -49.55269,-19.682 -67.44794,-41.21723a6.04124,6.04124 0 0 1 -2.00248,-8.29565l31.12817,-50.93817a6.0412,6.0412 0 0 1 8.29563,-2.00246l67.44794,41.21724a6.04122,6.04122 0 0 1 2.00251,8.29562l-31.12817,50.93817a6.04122,6.04122 0 0 1 -8.29566,2.00248z"/>
 <path id="svg_8" fill="#fff" d="m366.468868,243.766357c-14.03813,-1.53249 -28.62794,-12.75237 -43.36166,-26.49818a5.65768,5.65768 0 0 1 -1.87538,-7.76914l26.752,-43.77706a5.65793,5.65793 0 0 1 7.76928,-1.87564l60.01949,36.67774a5.65791,5.65791 0 0 1 1.87524,7.76937l-16.57233,27.119a25.202,25.202 0 0 1 -34.60664,8.35391z"/>
 <path id="svg_9" fill="#b2c6c5" d="m379.777758,189.165587l-20.76489,34.19245a2.40023,2.40023 0 1 1 -4.10309,-2.4918l20.76493,-34.19245a2.40023,2.40023 0 1 1 4.10309,2.4918l-0.00004,0z"/>
 <path id="svg_10" fill="#b2c6c5" d="m388.796868,194.642867l-20.7649,34.19249a2.40023,2.40023 0 0 1 -4.10309,-2.49179l20.76493,-34.19245a2.40023,2.40023 0 0 1 4.10309,2.49179l-0.00003,-0.00004z"/>
 <path id="svg_11" fill="#2f2e41" d="m248.379978,303.950727s-4.38,67.54 -8,78c-3.93,11.32 -3.42,28.5 -9.32,44.95c-1.82,5.07995 -3.68,10.23 -5.54,15.34c-0.37,1.03 -0.75,2.06 -1.12,3.09c-2.43,6.65 -4.86,13.19 -7.18,19.37c-0.33,0.87 -0.66,1.73 -0.98,2.57995c-0.01,0.02 -0.01,0.04 -0.02,0.05005c-7.66,20.15 -14.22,36 -16.84,38.62c-2.8,2.8 -21.72,3.96 -45.1,4.28c-43.33,0.58 -101.99,-1.71 -101.99,-1.71l0.09,-23.57s0.52,-0.15 1.49,-0.44c1.79,-0.52 5.12,-1.49 9.47,-2.78c18.17,-5.36 54.18,-16.15 70.67,-22.11c4.66,-1.68 7.76,-2.98 8.46,-3.67c4.1,-4 5.91,0 8.51,4c2.6,4 3.4,0 6.8,-2.94c3.4,-2.94 11.06,1.1 11.06,1.1s0.07,-2.66 0.18,-6.9c0.16,-6.26 0.4,-16 0.64,-25.75c-0.01,-0.01 -0.01,-0.01 0,-0.01c0.09,-3.67005 0.18,-7.34 0.26,-10.82c0.07,-2.7 0.13,-5.29 0.19,-7.69c0.16,-7.19 0.27,-12.63 0.27,-13.99c0,-4.44 0,-0.99 3.51,-3.67005a15.67285,15.67285 0 0 0 1.49,-1.32995a17.32066,17.32066 0 0 0 2.06,-2.2c0.26,-0.56 -1.35,0.2 -3.44,-0.22a6.3166,6.3166 0 0 1 -3.03,-1.55c-4.41,-3.97 0.35,-16.15 0.35,-16.15c-10.02,-20.04 -9.3,-36.48 -4.61,-49.14a63.00711,63.00711 0 0 1 16.26,-23.83a59.50548,59.50548 0 0 1 8.9,-6.98l0.31,-1.65l3.39,-18.32995a38.51174,38.51174 0 0 1 24.38,-4.43006a56.3,56.3 0 0 1 17.99,5.83a66.76979,66.76979 0 0 1 12.85,8.44l-2.41,26.21001z"/>
 <polygon id="svg_12" fill="#ffb6b6" points="156.242 609.949 167.212 610.969 176.454 568.2 160.264 566.695 156.242 609.949"/>
 <path id="svg_13" fill="#2f2e41" d="m187.840008,626.710737l-16.3,-13.65l0.73,-7.85l-3.79,-0.13l-11.75,-0.38l-2.88,-0.1l-4.88995,27.51l8.23,0.76l2.19,-7.63l3.03,8.12l21.81,2.03a4.9262,4.9262 0 0 0 3.62,-8.68l-0.00005,0z"/>
 <path id="svg_14" fill="#2f2e41" d="m217.220008,464.700727c-4.17,22.72 -21.84,75.97 -32.52,107.14c-4.87,14.24 -8.27,23.87994 -8.27,23.87994l-5.55,-1.75l-9.36,-2.96l-3.42005,-1.08l-4.13995,-1.31s0.97,-51.22 0.32,-78.39c-0.22,-8.76 -0.6,-15.01995 -1.23,-16.26c-2.6,-5.1 1.75,-5.63995 6.34,-6.93c4.6,-1.29 1.01,-3.23 -0.78,-7.37c-1.21,-2.76 1.17005,-6.43 2.86005,-8.53a20.69953,20.69953 0 0 1 1.49,-1.69s-11.7,-3.98005 -24.93,-8.47c-0.01,0.01 -0.01,0 -0.01,0c-1.33,-0.45 -2.67005,-0.9 -4.01,-1.36c4.66,-1.68 7.76,-2.98 8.46,-3.67c4.1,-4 5.91,0 8.51,4c2.6,4 3.4,0 6.8,-2.94c3.4,-2.94 11.06,1.1 11.06,1.1s0.07,-2.66 0.18,-6.9c0.16,-6.26 0.4,-16 0.64,-25.75c-0.01,-0.01 -0.01,-0.01 0,-0.01c0.09,-3.67005 0.18,-7.34 0.26,-10.82c7.05,5.4 13.89,10.7 20.09,15.6c0.01,0.01 0.03,0.02 0.03,0.04c14.91,11.78 26.1,21.24 27.67,24.24c0.7099,1.36004 0.46991,4.97002 -0.49005,10.19006z"/>
 <path id="svg_15" fill="#b2c6c5" d="m176.281058,292.822237c-4.688,-1.43009 -9.61695,3.398 -9.47423,8.29717s4.05481,9.04669 8.56137,10.97369s75.94495,13.95811 81.23128,11.23991s9.12727,-9.238 6.793,-14.70476c-1.50322,-3.52041 -4.744,-19.88885 -7.6186,-22.41669c-9.67617,-8.50906 -0.79647,-19.608 5.17825,-31.02442c5.28468,-10.09792 16.67872,-17.8219 16.56534,-29.21852c-0.08149,-8.1913 -6.2772,-14.8653 -12.07846,-20.64885l-18.44468,-18.38841l-17.51011,-17.45667c-3.74175,-3.73034 -19.79406,-5.70364 -22.66812,-3.97019c-6.00633,3.62263 -20.86818,63.98136 -22.99181,69.48638c-0.68188,1.76763 3.74927,24.3886 5.55569,24.95982c4.99161,15.81755 -3.78642,18.87019 -13.32841,32.43712"/>
 <path id="svg_16" fill="#ffb6b6" d="m319.581148,201.450807a7.74429,7.74429 0 0 0 -5.68285,9.18583a7.42941,7.42941 0 0 0 0.38194,1.16731l-19.91744,18.25464l5.70691,13.36552l24.58082,-27.95648a7.72278,7.72278 0 0 0 3.99879,-8.53837a7.39068,7.39068 0 0 0 -8.87109,-5.52708q-0.09886,0.02297 -0.19708,0.04863z"/>
 <path id="svg_17" fill="#b2c6c5" d="m229.921788,185.487357l0,0a13.37258,13.37258 0 0 0 -8.22982,18.48875l13.1234,27.91665l34.1097,39.781s34.72283,-18.63666 36.75618,-26.43829s-0.68064,-10.1753 2.03334,-7.80164s5.81867,1.77288 4.95742,0.65117s-0.46408,-1.13308 0.52417,-2.48439s4.87433,-7.901 4.87433,-7.901l-9.168,-11.79443s-11.99343,3.97189 -13.37561,6.81611s2.714,2.37366 -1.38219,2.84421s-8.80337,-4.72214 -5.28812,0.54827s2.9051,7.27891 -0.886,6.74521s-12.03381,8.4695 -12.03381,8.4695l-30.74913,-50.04242a13.37257,13.37257 0 0 0 -15.26586,-5.7987z"/>
 <circle id="svg_18" fill="#ffb6b6" r="29.89763" cy="128.05389" cx="218.48235"/>
 <path id="svg_19" fill="#2f2e41" d="m195.379978,105.950727c0.30768,-2.69066 2.15182,-6.01618 3.43459,-8.40131s3.75986,-4.33293 6.46294,-4.16671a6.89389,6.89389 0 0 1 5.28144,3.58917a14.45722,14.45722 0 0 1 1.64289,6.38285a33.42934,33.42934 0 0 1 -10.87013,26.61787c-2.0954,1.87931 -4.61715,3.5518 -7.43113,3.61545a9.58515,9.58515 0 0 1 -6.78842,-3.05324c-4.55482,-4.5616 -5.24355,-12.06085 -2.811,-18.03054s3.51812,-8.29263 9.07879,-11.55354"/>
 <path id="svg_20" fill="#31303a" d="m231.379978,150.950727c-0.5,2.66 4.1,15.31 2.63,17.59c0,0 -15.22142,5.28689 -21.04143,5.66689c-7.11,0.46 -12.82857,-2.45687 -19.47859,-6.84688a14.93,14.93 0 0 0 -2.01,-6.76a9.04542,9.04542 0 0 1 -0.94,4.62c-9.74,-7.75994 -13.3961,-29.09914 -3.7661,-39.00912l10,-23c-3,1 6.05614,-14.45084 15.60612,-3.26083c11.95,-12.17005 35.09981,5.34987 38.0002,21c-2.791,-3.53247 -4.24463,-3.116 -4.96045,-0.67993a13.77522,13.77522 0 0 0 -3.79976,-5.31007a11.5133,11.5133 0 0 1 1.24,5.31c-18.85,1.17 -15.34159,13.8868 0.1086,32.93685c-5.71338,-3.55664 -1.85791,11.76978 -4.90869,15.43311c-0.14991,-2.56012 -6.13992,-15.20003 -6.6799,-17.69002z"/>
 <path id="svg_21" fill="#3f3d56" d="m357.946868,437.950727l81.4331,0a1,1 0 0 0 0,-2l-81.4331,0a1,1 0 0 0 0,2z"/>
 <path id="svg_22" fill="#3f3d56" d="m396.946868,119.950727l81.4331,0a1,1 0 0 0 0,-2l-81.4331,0a1,1 0 0 0 0,2z"/>
 <path id="svg_23" fill="#e6e6e6" d="m436.546698,110.477897c-16.51077,-21.96122 -26.0537,-46.51942 -26.36641,-74.51776a6.04125,6.04125 0 0 1 3.67595,-7.70164l56.27746,-19.91249a6.04122,6.04122 0 0 1 7.70161,3.676l26.3664,74.51777a6.04121,6.04121 0 0 1 -3.67591,7.70162l-56.27746,19.91249a6.04121,6.04121 0 0 1 -7.70164,-3.67599z"/>
 <path id="svg_24" fill="#fff" d="m432.899768,88.443017c-9.93022,-10.04034 -14.1809,-27.94788 -16.95072,-47.90679a5.65769,5.65769 0 0 1 3.44264,-7.21282l48.36574,-17.1131a5.65791,5.65791 0 0 1 7.21308,3.44253l23.46251,66.31068a5.65791,5.65791 0 0 1 -3.4429,7.21291l-29.96157,10.60122a25.202,25.202 0 0 1 -32.12878,-15.33463z"/>
 <path id="svg_25" fill="#4d5253" d="m475.617918,53.374717l-37.67558,13.44829a2.40023,2.40023 0 0 1 -1.6138,-4.52107l37.67559,-13.44829a2.40023,2.40023 0 0 1 1.61379,4.52107z"/>
 <path id="svg_27" fill="#4d5253" d="m482.712578,73.250527l-37.67558,13.44829a2.40023,2.40023 0 0 1 -1.61379,-4.52107l37.67558,-13.44829a2.40023,2.40023 0 0 1 1.61379,4.52107z"/>
 <path id="svg_28" fill="#4d5253" d="m479.165248,63.312627l-37.67558,13.44828a2.40023,2.40023 0 0 1 -1.6138,-4.52107l37.67559,-13.44828a2.40023,2.40023 0 1 1 1.61379,4.52107z"/>
 <circle transform="rotate(-9.235857963562012 413.37997436523443,34.95071792602555) " id="svg_29" fill="#4d5253" r="10" cy="34.95072" cx="413.37998"/>
 <path id="svg_30" fill="#e6e6e6" d="m419.546698,419.477897c-16.51077,-21.96122 -26.0537,-46.51942 -26.36641,-74.51776a6.04125,6.04125 0 0 1 3.67595,-7.70164l56.27746,-19.91249a6.04122,6.04122 0 0 1 7.70161,3.676l26.3664,74.51777a6.04121,6.04121 0 0 1 -3.67591,7.70162l-56.27746,19.91249a6.04121,6.04121 0 0 1 -7.70164,-3.67599z"/>
 <path id="svg_31" fill="#fff" d="m415.899768,398.443017c-9.93022,-10.04034 -14.1809,-27.94788 -16.95072,-47.90679a5.65769,5.65769 0 0 1 3.44264,-7.21282l48.36574,-17.1131a5.65791,5.65791 0 0 1 7.21308,3.44253l23.46251,66.31068a5.65791,5.65791 0 0 1 -3.4429,7.21291l-29.96157,10.60122a25.202,25.202 0 0 1 -32.12878,-15.33463z"/>
 <path id="svg_32" fill="#6d887f" d="m458.617918,362.374717l-37.67558,13.44829a2.40023,2.40023 0 0 1 -1.6138,-4.52107l37.67559,-13.44829a2.40023,2.40023 0 0 1 1.61379,4.52107z"/>
 <path id="svg_33" fill="#6d887f" d="m424.805868,360.467437l-8.28863,2.95862a2.40023,2.40023 0 0 1 -1.61379,-4.52107l8.28863,-2.95862a2.40023,2.40023 0 1 1 1.61379,4.52107z"/>
 <path id="svg_34" fill="#6d887f" d="m462.165248,372.312627l-37.67558,13.44828a2.40023,2.40023 0 0 1 -1.6138,-4.52107l37.67559,-13.44828a2.40023,2.40023 0 1 1 1.61379,4.52107z"/>
 <circle id="svg_35" fill="#6d887f" r="10" cy="341.95072" cx="398.37998"/>
 <circle id="svg_36" fill="none" r="10" cy="161.84836" cx="354.08079"/>
 <path id="svg_37" fill="#b2c6c5" d="m205.177598,123.457727a1.00011,1.00011 0 0 1 -0.273,-1.96191c0.26221,-0.43262 0.15479,-2.25342 0.06836,-3.72461c-0.32519,-5.52393 -0.86963,-14.769 8.106,-21.00977a0.99976,0.99976 0 0 1 1.1416,1.6416c-8.05469,5.60108 -7.57129,13.81543 -7.251,19.25049c0.18262,3.10742 0.31494,5.352 -1.56982,5.7793a0.99351,0.99351 0 0 1 -0.22214,0.0249z"/>
 <path id="svg_38" fill="#ccc" d="m14.261308,635.233117a1.18647,1.18647 0 0 0 1.19006,1.19l253.29,0a1.19,1.19 0 0 0 0,-2.38l-253.29,0a1.1865,1.1865 0 0 0 -1.19006,1.19z"/>
 <path id="svg_39" fill="#2f2e41" d="m210.643658,102.601707a56.40248,56.40248 0 0 0 -19.20647,-4.25648c-5.2367,-0.22192 -11.48451,0.94577 -13.6133,5.7354c-1.29144,2.90564 -0.60343,6.31148 0.57791,9.26361s2.83917,5.74133 3.52323,8.84659c1.47365,6.68966 -2.00452,13.77909 -7.34264,18.07184s-12.2271,6.18709 -19.02279,7.04834c5.10568,1.89432 10.28959,3.80662 15.71637,4.261s11.22244,-0.72533 15.27427,-4.36389c4.3847,-3.93747 6.0695,-10.053 6.81534,-15.89878s0.78443,-11.86628 2.766,-17.41629s6.53338,-10.71869 12.40595,-11.21089"/>
</g>
</svg>
<motion.div
  className='soc'
  variants={variants}
  initial='hidden'
  animate='visible'
  transition={transition}
>
  <motion.button
          className="round-butt"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 1.9 }}
          onClick={handleCallButtonClick}
        >
          Получить консультацию
        </motion.button>
</motion.div>
        </div>
        
        </div>

       
    )
}