import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export const AccPrice = () => {
  const [ref1, inView1] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const services = [
    {name: 'ИП (1 наемный работник) слудющие работники + 500р/за чел.', price: <strong>от 2000р</strong>},
    {name: 'ООО (УСН) (1 наемный работник)слудющие работники + 500р/за чел.', price: <strong>от 5000р</strong>},
    {name: 'ООО (ОСН) (1 наемный работник)слудющие работники + 500р/за чел.', price: <strong>от 7000р</strong>},
    {name: 'Востановление бучгалерского учета', price: <strong>договорная</strong>},
    {name: 'Консультация для действующих клиентов:', price: <strong>Бесплатно</strong>}
  ]
  const advantages = [
    {
      title: 'Экономия средств',
      description: 'Аутсорсинг позволяет компаниям сокращать расходы за счет снижения затрат на рабочую силу.'
    },
    {
      title: 'Повышенная эффективность',
      description: 'Наша компания специализируется на конкретных задачах, что приводит к повышению эффективности, улучшению качества и сокращению сроков выполнения работ.'
    },
    {
      title: 'Конкурентное преимущество',
      description: 'Аутсорсинг может помочь организациям получить конкурентное преимущество, сосредоточившись на инновациях, расширении рынка и оставаясь впереди в быстро меняющемся бизнес-ландшафте.'
    }
  ];
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.4,
      },
    },
  };

  const flipUnitVariants = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };


  return (
    <div className="price-main">
      
      <h1 className="pr_ma_txt">ОСНОВНЫЕ ПРЕИМУЩЕСТВА АУТСОРСИНГА</h1>
      <div className="advantages">
        {advantages.map((advantage, index) => (
          <div className="advantage-item" key={index}>
            {index === 0 && (
              <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
                <path d="M479-82q-74 0-139.5-28t-114-76.5q-48.5-48.5-77-114T120-440.733q0-74.733 28.5-140T225.5-695q48.5-49 114-77T479-800q74 0 139.5 28T733-695q49 49 77 114.267t28 140Q838-366 810-300.5t-77 114Q684-138 618.5-110T479-82Zm0-357Zm121 161 42-42-130-130v-190h-60v214l148 148ZM214-867l42 42L92-667l-42-42 164-158Zm530 0 164 158-42 42-164-158 42-42ZM479.043-142Q604-142 691-229.043t87-212Q778-566 690.957-653t-212-87Q354-740 267-652.957t-87 212Q180-316 267.043-229t212 87Z"/>
              </svg>
              
            )}
             {index === 1 && (
              <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
                <path d="m187-551 106 45q18-36 38.5-71t43.5-67l-79-16-109 109Zm154 81 133 133q57-26 107-59t81-64q81-81 119-166t41-192q-107 3-192 41T464-658q-31 31-64 81t-59 107Zm229-96q-20-20-20-49.5t20-49.5q20-20 49.5-20t49.5 20q20 20 20 49.5T669-566q-20 20-49.5 20T570-566Zm-15 383 109-109-16-79q-32 23-67 43.5T510-289l45 106Zm326-694q9 136-34 248T705-418l-2 2-2 2 22 110q3 15-1.5 29T706-250L535-78l-85-198-170-170-198-85 172-171q11-11 25-15.5t29-1.5l110 22q1-1 2-1.5t2-1.5q99-99 211-142.5T881-877ZM149-325q35-35 85.5-35.5T320-326q35 35 34.5 85.5T319-155q-26 26-80.5 43T75-80q15-109 31.5-164t42.5-81Zm42 43q-14 15-25 47t-19 82q50-8 82-19t47-25q19-17 19.5-42.5T278-284q-19-18-44.5-17.5T191-282Z"/>
              </svg>
              
            )}
             {index === 2 && (
              <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
               <path d="M660-80v-86.667Q606-184 568.5-225T523-320h61q10 44 44 72t82 28h120q20.833 0 35.417 14.583Q880-190.833 880-170v90H660Zm109.956-195Q739-275 717-297.044q-22-22.045-22-53Q695-381 717.044-403q22.045-22 53-22Q801-425 823-402.956q22 22.045 22 53Q845-319 822.956-297q-22.045 22-53 22ZM390-420q0-126 87-213t213-87v60q-101 0-170.5 69.5T450-420h-60Zm120 0q0-75 52.65-127.5T690-600v60q-50 0-85 35t-35 85h-60ZM80-535v-90q0-20.833 15-35.417Q110-675 130-675h120q48 0 82-28t44-72h61q-8 54-45.5 95T300-621.667V-535H80Zm109.956-195Q159-730 137-752.044q-22-22.045-22-53Q115-836 137.044-858q22.045-22 53-22Q221-880 243-857.956q22 22.045 22 53Q265-774 242.956-752q-22.045 22-53 22Z"/>
              </svg>
              
            )}
            <h2 className="advantage-title">{advantage.title}</h2>
            <p className="advantage-description">{advantage.description}</p>
          </div>
        ))}
      </div>
      <h1 className="pr_ma_txt">
      Цены на бухгалтерский аутсорсинг
      </h1>
      <p className="ftt">
      Расценки на услуги бухгалтерского аутсорсинга определяются исходя из списка предлагаемых услуг, как для индивидуальных предпринимателей, так и для юридических лиц. В Симферополе, Крыму и других городах России, стоимость услуг бухгалтерского аутсорсинга от данной компании представлена в рублях.
      </p>
      <div className="services">
      <motion.div
      ref={ref1}
          variants={containerVariants}
          initial="hidden"
          animate={inView1 ? "visible" : "hidden"}
          className="services"
        >
        {services.map((service, index) => (
            <motion.div
            key={index}
            variants={flipUnitVariants}
            
            className="service-cardd"
          >
          <div key={index}>
            <div className="service-name">{service.name}</div>
            <div className="service-price">{service.price}</div>
          </div>
          </motion.div>
        ))}
        </motion.div>
      </div>
      <p className="ftt">

*Не является публичной офертой, окончательная цена зависит от спектра и 
характера услуг. Свяжитесь с нами для определения стоимости услуг 
+79785936812
      </p>
    </div>
  );
};