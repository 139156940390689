import React from "react";
import './Services.css';
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const services = [
  "Аудит текущего состояния кадрового учета компании",
  "Прием, увольнения, изменение условий труда",
  "Оформление командировок, отпусков, работы сверхурочно и т.д.",
  "Формирование графика отпусков, штатного расписания",
  "Оформление табеля учета рабочего времени",
  "Ведение журналов регистрации документов",
  "Оформление справок с места работы, копий трудовых книжек и других документов по запросам",
  "Ведение воинского учета на предприятии",
  "Формирование архива личных дел",
  "Подготовка данных и сдача кадровой отчетности компании",
];
const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const serviceVariants = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};


export const ServicesHR = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
    return (
      <div className="ServicesHR-main" >
        
        <div className="background-hr">
        <svg xmlns="http://www.w3.org/2000/svg"  preserveAspectRatio="none" viewBox="0 0 500 500" >
    <path fill="#2b2937" d="M1,20 Q200,100 400,100 T500, 100 V370 Q300, 320 100, 380 T0,200 Z" />
</svg>
        </div>
          <div className="firsttext_services">
            <h1 className="fts">
            Что включает в себя услуга ведения <br /> кадрового делопроизводства
            </h1>
          </div>
          <motion.div
          ref={ref}
          variants={containerVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          className="services-container"
        >
          {services.map((service, index) => (
           <motion.div
           key={index}
           variants={serviceVariants}
           className="service-card"
         >
           <div className="service-description">{service}</div>
         </motion.div>
          ))}
         </motion.div>
          </div>
       
      
    );
  };