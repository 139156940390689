/* eslint-disable no-undef */
import React, { useCallback, memo ,useState} from 'react';
import './MainHR.css';
import { Link } from 'react-router-dom';
import { motion} from 'framer-motion';
import { ReactComponent as Hr } from './Hr.svg';
import { ReactComponent as X } from './X.svg';

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const transition = {
  duration: 2,
  yoyo: Infinity, 
};

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.4,
    },
  },
};
const flipUnitVariants = {
  hidden: {
    opacity: 0,
    y: 20, 
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const AnimatedCard = memo(({ animation, digit }) => {
  return(
    <div className={`flipCard ${animation}`}>
      <span>{digit}</span>
    </div>
  );
});

const StaticCard = memo(({ position, digit }) => {
  return(
    <div className={position}>
      <span>{digit}</span>
    </div>
  );
});

const FlipUnitContainer = memo(({ digit, shuffle }) => {
  let currentDigit = digit;
  let previousDigit = digit - 1;

  if ( previousDigit < 0 ) 
    previousDigit = 0;

  const digit1 = shuffle ? previousDigit : currentDigit;
  const digit2 = !shuffle ? previousDigit : currentDigit;

  const animation1 = shuffle ? 'fold' : 'unfold';
  const animation2 = !shuffle ? 'fold' : 'unfold';

  return(
    <div className={'flipUnitContainer'}>
      <StaticCard 
        position={'upperCard'} 
        digit={currentDigit} 
        />
      <StaticCard 
        position={'lowerCard'} 
        digit={previousDigit} 
        />
      <AnimatedCard 
        digit={digit1}
        animation={animation1}
        />
      <AnimatedCard 
        digit={digit2}
        animation={animation2}
        />
    </div>
  );
});///////////////////

export const MainHR = () => {

  

  const EXPERIENCE_START = 9;
  const EXPERIENCE_END = 15; 
  const COMPANIES_START = 29;
  const COMPANIES_END = 35; 
  const CLIENTS_START = 994;
  const CLIENTS_END = 1000;


  const [experience, setExperience] = React.useState(EXPERIENCE_START);
  const [experienceShuffle, setExperienceShuffle] = React.useState(false);

  const [companies, setCompanies] = React.useState(COMPANIES_START);
  const [companiesShuffle, setCompaniesShuffle] = React.useState(false);

  const [clients, setClients] = React.useState(CLIENTS_START);
  const [clientsShuffle, setClientsShuffle] = React.useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [acceptedTerms, setAcceptedTerms] = useState(false);

const [isSuccess, setIsSuccess] = useState(false);


  const handleConsultationButtonClick = () => {
    ym(94628141, 'reachGoal', 'send_request');
    
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);

  };
    

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Получаем значения полей
    const formData = new FormData(e.target);
    const name = formData.get("name");
    const phone = formData.get("phone");
    const comment = formData.get("comment");
  
    // Устанавливаем состояние отправки (если используется)
    setSubmitting(true);
  
    fetch('https://citadel-konsalt.store/consultation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, phone, comment }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.text().then(text => Promise.reject(text));
        }
        return response.json();
      })
      .then((data) => {
      
        setSubmitting(false);
        setIsSuccess(true);
        setTimeout(() => {
          handleCloseModal();

        }, 5000); // Закрыть модальное окно через 5 секунд
      })
      .catch((error) => {
        console.error('Ошибка:', error);
        setSubmitting(false);
      });
  };

  const incrementExperience = useCallback(() => {
    if (experience < EXPERIENCE_END) {
      setExperienceShuffle(!experienceShuffle);
      return experience + 1;
    }
    return experience;
  }, [experience, experienceShuffle]);

  const incrementCompanies = useCallback(() => {
    if (companies < COMPANIES_END) {
      setCompaniesShuffle(!companiesShuffle);
      return companies + 1;
    }
    return companies;
  }, [companies, companiesShuffle]);

  const incrementClients = useCallback(() => {
    if (clients < CLIENTS_END) {
      setClientsShuffle(!clientsShuffle);
      return clients + 1;
    }
    return clients;
  }, [clients, clientsShuffle]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setExperience(incrementExperience);
      setCompanies(incrementCompanies);
      setClients(incrementClients);
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, [incrementExperience, incrementCompanies, incrementClients]);


  return (
    <div className="MainHR-container">
        <div>
        <motion.h1 
        className='main-text'
        variants={variants}
        initial='hidden'
        animate='visible'
        transition={transition}
      >
        ВЕДЕНИЕ КАДРОВОГО ДЕЛОПРОИЗВОДСТВА
      </motion.h1>
      <motion.p
        className='sub-text'
        variants={variants}
        initial='hidden'
        animate='visible'
        transition={{...transition, delay: 0.5}}
      >
        Ваша экономия - наша задача....
      </motion.p>
      <div className='line'>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 80">
  <path fill="none" stroke="rgb(182, 182, 182)"  d="M0,50 Q240,10 480,50 Q720,90 960,50 Q1200,10 1440,50"></path>
</svg>
      </div>
      <motion.div
  className='opit'
  variants={containerVariants}
  initial='hidden'
  animate='visible'
>
  <motion.div className='module' variants={flipUnitVariants}>
    <motion.div className='number'>
      <FlipUnitContainer digit={experience} shuffle={experienceShuffle} />
    </motion.div>
    <div className='text'>более лет опыта</div>
  </motion.div>
  <motion.div className='module' variants={flipUnitVariants}>
    <motion.div className='number'>
      <FlipUnitContainer digit={companies} shuffle={companiesShuffle} />
    </motion.div>
    <div className='text'>более предприятий</div>
  </motion.div>
  <motion.div className='module' variants={flipUnitVariants}>
    <motion.div className='number'>
      <FlipUnitContainer digit={clients} shuffle={clientsShuffle} />
    </motion.div>
    <div className='text'>более довольных клиентов</div>
  </motion.div>
</motion.div>
        </div>
        <div className='img-container'>
   <Hr/>

    <motion.div
    className='socbut'
    variants={variants}
    initial='hidden'
    animate='visible'
    transition={transition}
  >
    <motion.button
          className="round-button"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={handleConsultationButtonClick}
        >
          Записаться на консультацию
        </motion.button>
        <div className="wave"></div>
      </motion.div>
      {modalVisible && (
  <div className="modal">
    {isSuccess ? (
      <div className="success-message">
        Ваша заявка принята! Спасибо за обращение, в ближайшее время с вами свяжется наш менеджер!
      </div>
    ) : (
      <>
        <button onClick={handleCloseModal} className="closee-button">
         <X/>
        </button>
        <form className="md" onSubmit={handleSubmit}>
          <label>
            Имя:
            <input type="text" name="name" required />
          </label>
          <label>
            Телефон:
            <input type="tel" name="phone" required />
          </label>
          <label>
            Комментарий:
            <textarea name="comment"></textarea>
          </label>
          <span>
    <Link to="/privacy-policy">Принять условия соглашения:</Link>
   </span>
   
<input
    type="checkbox"
    id="accept-terms-checkbox"  // устанавливаем id для чекбокса
    name="checkbox-name"
    onChange={(e) => setAcceptedTerms(e.target.checked)}
/>
<label htmlFor="accept-terms-checkbox"></label>
          <button type="submit" disabled={!acceptedTerms || submitting}>
            {submitting ? 'Отправка...' : 'Отправить'}
          </button>
        </form>
      </>
    )}
  </div>
)}
    <div className="wave"></div>
    

        </div>
      
    </div>
  );
};