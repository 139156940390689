import {useEffect} from 'react';
import { LegalMain } from './LegalMain/LegalMain';
import { ServandPrice } from './ServandPrice/ServandPrice';
import { LegalCont } from './LegalCont/LegalCont';
import { ScrollToTopButton } from '../ScrollToTopButton/ScrollToTopButton';
export const Legal = () => {
  useEffect(() => {
    // Подключаем скрипт здесь
    window.YandexRotorSettings = {
      WaiterEnabled: true,
      FailOnTimeout: false,
      NoJsRedirectsToMain: true
    };
    // Здесь можно выполнить дополнительные действия при монтировании компонента, если это необходимо
  }, []);
  return (
    <div className="main-container">
<LegalMain />
<ServandPrice />
<LegalCont />
<ScrollToTopButton />
    </div>
  );
};
