import {useEffect} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { HelmetProvider } from 'react-helmet-async'; 
import { Main } from './components/HRServices/HRServices';
import { MainAccountantn } from './components/Accountant/MainAccountant';
import { Sfera } from './components/Sfera/Sfera';
import { Navbar } from './components/Navbar/Navbar';
import { Ecology } from './components/Ecology/Ecology';
import { Legal } from './components/Legal/Legal';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import NotFound from './components/NotFound/NotFound';
import YourComponent from './components/YourComponent';

const App = () => {
  useEffect(() => {
    console.log('useEffect is running');
    window.YandexRotorSettings = {
      WaiterEnabled: true,
      FailOnTimeout: false,
      NoJsRedirectsToMain: true
    };
  }, []);
  return (
    <BrowserRouter>
      <HelmetProvider> 
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Helmet>
                  <title>Кадровое Делопроизводство | ЦИТАДЕЛЬ КОНСАЛТ</title>
                  <meta name="description" content="Комплексные услуги по ведению кадрового делопроизводства. Профессиональный подход, гибкие решения." />
                </Helmet>
                <Main />
              </>
            }
            exact
          />
          <Route
            path="/accounting-services"
            element={
              <>
                <Helmet>
                  <title>Бухгалтерские Услуги | ЦИТАДЕЛЬ КОНСАЛТ</title>
                  <meta name="description" content="Аутсорсинг бухгалтерских услуг для вашего бизнеса. Эффективное управление финансами с нашей помощью." />
                </Helmet>
                <MainAccountantn />
              </>
            }
          />
          <Route
            path="/work-protection"
            element={
              <>
                <Helmet>
                  <title>Охрана Труда | Учебный Центр «Сфера»</title>
                  <meta name="description" content="Сертифицированные курсы по охране труда и промышленной безопасности. Повышение квалификации с экспертами «Сфера»." />
                </Helmet>
                <Sfera />
              </>
            }
          />
          <Route
            path="/eco-support"
            element={
              <>
                <Helmet>
                  <title>Экологическая Поддержка | ЦИТАДЕЛЬ КОНСАЛТ</title>
                  <meta name="description" content="Полный спектр услуг по экологическому сопровождению и консалтингу. Забота о природе с Вашей Компанией." />
                </Helmet>
                <Ecology />
              </>
            }
          />
          <Route
            path="/legal-services"
            element={
              <>
                <Helmet>
                  <title>Юридические Услуги | ЦИТАДЕЛЬ КОНСАЛТ</title>
                  <meta name="description" content="Профессиональные юридические консультации и услуги. Решение правовых вопросов с надежным партнером." />
                </Helmet>
                <Legal />
              </>
            }
          />
          <Route  element={<NotFound />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </HelmetProvider>
      <YourComponent />
    </BrowserRouter>
  );
};

export default App;