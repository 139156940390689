import {useEffect} from 'react';
import {Perspectives} from '../HRServices/Perspectives/Perspectives';
import { Accountant } from './AccountingMain.jsx/AccountantMain';
import { AccServices } from './AccServices/AccServices';
import { AccPrice } from './AccPrice/AccPrice';
import { ContactsAcc } from './СontactsAcc/СontactsAcc';
import { ScrollToTopButton } from '../ScrollToTopButton/ScrollToTopButton';

export const MainAccountantn = () => {
  useEffect(() => {
    // Подключаем скрипт здесь
    window.YandexRotorSettings = {
      WaiterEnabled: true,
      FailOnTimeout: false,
      NoJsRedirectsToMain: true
    };
    // Здесь можно выполнить дополнительные действия при монтировании компонента, если это необходимо
  }, []);
  return (
    <div className="main-container">
  <Accountant/>
  <Perspectives/>
  <AccServices/>
  <AccPrice/>
  <ContactsAcc/>
  <ScrollToTopButton />
    </div>
  );
};

