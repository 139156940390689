import React from 'react';
import notfound from './404.svg'

import './NotFound.css';

const NotFound = () => {
    return (
 
      <div className='notfound'>
        <p className='nottext'>Запрошенная страница не существует.</p>
        <img src={notfound} alt="" className='notimage' />
        
      </div>
   
    );
  };
  
  export default NotFound;