import React from "react";
import "./EcoServices.css";
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export const EcoServices = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, 
    threshold: 0.2,
  });
 
  const itemVariants = {
    hidden: { opacity: 0, y: 120 },
    visible: { opacity: 1, y: 0 },
  };

  const itemTransition = {
    duration: 0.5,
  };
  return (
    <motion.div
    ref={ref}
      className="eco-services"
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={itemVariants}
      transition={itemTransition}
    >
      <h1 className="pr_ma_txt">Разрабатываем проекты и документацию любой сложности</h1>
      <div className="eco-mm">
      <a href="https://bisbur.ru/work/" className="eco-bu">Посмотреть все услуги</a>
        <div className="eco-item">
          <h2 className="eco_ft">Отходы и утилизация</h2>
          <div className="eco-content">
            <p className="eco_st">
              Мы предлагаем разработку отчетов, документации и программ, а также проведение исследований в области
              утилизации отходов.
            </p>
            <div className="svg_cont">
              <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
                <path d="M261-120q-24.75 0-42.375-17.625T201-180v-570h-41v-60h188v-30h264v30h188v60h-41v570q0 24-18 42t-42 18H261Zm438-630H261v570h438v-570ZM367-266h60v-399h-60v399Zm166 0h60v-399h-60v399ZM261-750v570-570Z" />
              </svg>
            </div>
            <a href="https://bisbur.ru/work/#1" className="eco-button">Подробнее</a>
          </div>
        </div>

        <div className="eco-item">
          <h2 className="eco_ft">Воздух и выбросы в атмосферу</h2>
          <div className="eco-content">
            <p className="eco_st">
              Наша команда проводит инвентаризацию выбросов, помогает в постановке на учет, разрабатывает проекты НДВ/НМУ и
              формирует отчетность в области воздушной среды.
            </p>
            <div className="svg_cont">
              <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
                <path d="M465-160q-54 0-85.5-28T348-273h68q0 26 11.5 39.5T465-220q27 0 38.5-12t11.5-41q0-29-11.5-42.5T465-329H80v-60h385q54 0 82 28t28 88q0 57-28 85t-82 28ZM80-568v-60h548q37 0 54-17.5t17-58.5q0-41-17-58.5T628-780q-38 0-55 20.5T556-708h-60q0-58 35-95t97-37q61 0 96 35.5T759-704q0 65-35 100.5T628-568H80Zm672 330v-60q35 0 51.5-19.5T820-374q0-38-18.5-55T748-446H80v-60h668q62 0 97 35t35 97q0 64-33 100t-95 36Z" />
              </svg>
            </div>
            <a href="https://bisbur.ru/work/#2" className="eco-button">Подробнее</a>
          </div>
        </div>

        <div className="eco-item">
          <h2 className="eco_ft">Санитарно-защитная зона (СЗЗ)</h2>
          <div className="eco-content">
            <p className="eco_st">
              Мы разрабатываем проекты санитарной защитной зоны и осуществляем контроль на границах, обеспечивая
              безопасность и охрану окружающей среды.
            </p>
            <div className="svg_cont">
              <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
                <path d="M480-81q-140-35-230-162.5T160-523v-238l320-120 320 120v238q0 152-90 279.5T480-81Zm0-62q115-38 187.5-143.5T740-523v-196l-260-98-260 98v196q0 131 72.5 236.5T480-143Zm0-337Z" />
              </svg>
            </div>
            <a href="https://bisbur.ru/work/#3" className="eco-button">Подробнее</a>
          </div>
        </div>

        <div className="eco-item">
          <h2 className="eco_ft">Недропользование и водные ресурсы</h2>
          <div className="eco-content">
            <p className="eco_st">
              Наша команда помогает в получении лицензий, разрабатывает проекты ЗСО/ГИН и формирует отчетность в области
              недропользования и водных ресурсов.
            </p>
            <div className="svg_cont">
              <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
                <path d="M480-100q-133 0-226.5-91.709T160-415q0-63.142 24.5-120.771Q209-593.401 254-637.5L480-860l226 222.5q45 44.099 69.5 101.729Q800-478.142 800-415q0 131.582-93.5 223.291T480-100ZM220-415h520q0-51-19.5-97T666-593L480-776 294-593q-35 35-54.5 81.042Q220-465.917 220-415Z" />
              </svg>
            </div>
            <a href="https://bisbur.ru/work/#4" className="eco-button">Подробнее</a>
          </div>
        </div>

        <div className="eco-item">
          <h2 className="eco_ft">Экологическое сопровождение</h2>
          <div className="eco-content">
            <p className="eco_st">
              Мы предлагаем полное экологическое сопровождение вашего проекта, а также возможность включить эколога в штат
              вашей компании.
            </p>
            <div className="svg_cont">
              <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
                <path d="M280-412q-28 0-48-20t-20-48q0-28 20-48t48-20q28 0 48 20t20 48q0 28-20 48t-48 20Zm0 172q-100 0-170-70T40-480q0-100 70-170t170-70q72 0 126 34t85 103h356l113 113-167 153-88-64-88 64-75-60h-51q-25 60-78.5 98.5T280-240Zm0-60q58 0 107-38.5t63-98.5h114l54 45 88-63 82 62 85-79-51-51H450q-12-56-60-96.5T280-660q-75 0-127.5 52.5T100-480q0 75 52.5 127.5T280-300Z" />
              </svg>
            </div>
            <a href="https://bisbur.ru/work/#5" className="eco-button">Подробнее</a>
          </div>
        </div>
      </div>
      </motion.div>
  );
};