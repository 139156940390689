import React from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import './DopServices.css';
import sfera from './sfera.png';

export const DopServices = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <div ref={ref} className="dop-container">
      <motion.h1
        className="sferah1"
        initial={{ opacity: 0 }}
        animate={{ opacity: inView ? 1 : 0 }}
        transition={{ duration: 1 }}
      >
        Полный список услуг в Крыму и Симфепрополе
      </motion.h1>

      <div className="services-grid">
        <div className="service-category">
          <h3 className="category-heading">Основные услуги</h3>
          <ul className="service-list">
            <li className="service-item">
              <motion.a
                href="https://sferaot.ru/drugie-uslugi/spetsialnaya-otsenka-uslovij-truda"
                target="_blank"
                rel="noopener noreferrer"
                initial={{ opacity: 0 }}
                animate={{ opacity: inView ? 1 : 0 }}
                transition={{ duration: 1 }}
              >
                Специальная оценка условий труда
              </motion.a>
            </li>
            <li className="service-item">
              <motion.a
                href="https://sferaot.ru/drugie-uslugi/pasportizatsiya-otkhodov"
                target="_blank"
                rel="noopener noreferrer"
                initial={{ opacity: 0 }}
                animate={{ opacity: inView ? 1 : 0 }}
                transition={{ duration: 1 }}
              >
                Паспортизация отходов
              </motion.a>
            </li>
            <li className="service-item">
              <motion.a
                href="https://sferaot.ru/drugie-uslugi/ekologicheskaya-otchetnost"
                target="_blank"
                rel="noopener noreferrer"
                initial={{ opacity: 0 }}
                animate={{ opacity: inView ? 1 : 0 }}
                transition={{ duration: 1 }}
              >
                Экологическая отчетность
              </motion.a>
            </li>
            <li className="service-item">
              <motion.a
                href="https://sferaot.ru/drugie-uslugi/servis-obsluzhivanie-po-okhrane-truda"
                target="_blank"
                rel="noopener noreferrer"
                initial={{ opacity: 0 }}
                animate={{ opacity: inView ? 1 : 0 }}
                transition={{ duration: 1 }}
              >
                Сервисное обслуживание по охране труда
              </motion.a>
            </li>
            <li className="service-item">
              <motion.a
                href="https://sferaot.ru/drugie-uslugi/proizvodstvo-i-prodazha-stendov"
                target="_blank"
                rel="noopener noreferrer"
                initial={{ opacity: 0 }}
                animate={{ opacity: inView ? 1 : 0 }}
                transition={{ duration: 1 }}
              >
                Оценка профессиональных рисков
              </motion.a>
            </li>
            <li className="service-item">
              <motion.a
                href="https://sferaot.ru/drugie-uslugi/zagolovok"
                target="_blank"
                rel="noopener noreferrer"
                initial={{ opacity: 0 }}
                animate={{ opacity: inView ? 1 : 0 }}
                transition={{ duration: 1 }}
              >
                Разработка документации для предприятий
              </motion.a>
            </li>
            <li className="service-item">
              <motion.a
                href="https://sferaot.ru/drugie-uslugi/izgotovlenie-plakatov-i-bannerov"
                target="_blank"
                rel="noopener noreferrer"
                initial={{ opacity: 0 }}
                animate={{ opacity: inView ? 1 : 0 }}
                transition={{ duration: 1 }}
              >
                Изготовление плакатов и баннеров
              </motion.a>
            </li>
          </ul>
          <div className="imgsfera">
            <motion.a
              href="https://sferaot.ru/"
              target="_blank"
              rel="noopener noreferrer"
              initial={{ opacity: 0 }}
              animate={{ opacity: inView ? 1 : 0 }}
              transition={{ duration: 1 }}
            >
              <motion.img
                src={sfera}
                alt="Сфера Охраны Труда"
                initial={{ opacity: 0 }}
                animate={{ opacity: inView ? 1 : 0 }}
                transition={{ duration: 1 }}
              />
            </motion.a>
          </div>
        </div>

        <div className="service-category">
          <h3 className="category-heading">Услуги по обучению</h3>
          <div className="sub-columns">
            <div className="sub-column">
              <ul className="service-list">
                <li className="service-item">
                  <motion.a
                    href="https://sferaot.ru/vidy-obucheniya/obuchenie-okhrane-truda"
                    target="_blank"
                    rel="noopener noreferrer"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: inView ? 1 : 0 }}
                    transition={{ duration: 1 }}
                  >
                    Охрана труда
                  </motion.a>
                </li>
                <li className="service-item">
                  <motion.a
                    href="https://sferaot.ru/vidy-obucheniya/elektrobezopasnost"
                    target="_blank"
                    rel="noopener noreferrer"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: inView ? 1 : 0 }}
                    transition={{ duration: 1 }}
                  >
                    Электробезопасность
                  </motion.a>
                </li>
                <li className="service-item">
                  <motion.a
                    href="https://sferaot.ru/vidy-obucheniya/promyshlennaya-bezopasnost"
                    target="_blank"
                    rel="noopener noreferrer"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: inView ? 1 : 0 }}
                    transition={{ duration: 1 }}
                  >
                    Промышленная безопасность
                  </motion.a>
                </li>
                <li className="service-item">
                  <motion.a
                    href="https://sferaot.ru/vidy-obucheniya/promyshlennaya-bezopasnost"
                    target="_blank"
                    rel="noopener noreferrer"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: inView ? 1 : 0 }}
                    transition={{ duration: 1 }}
                  >
                    Гражданская оборона
                  </motion.a>
                </li>
                <li className="service-item">
                  <motion.a
                    href="https://sferaot.ru/vidy-obucheniya/ekologicheskaya-bezopasnost"
                    target="_blank"
                    rel="noopener noreferrer"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: inView ? 1 : 0 }}
                    transition={{ duration: 1 }}
                  >
                    Экологическая безопасность
                  </motion.a>
                </li>
                <li className="service-item">
                  <motion.a
                    href="https://sferaot.ru/vidy-obucheniya/bezopasnost-dorozhnogo-dvizheniya"
                    target="_blank"
                    rel="noopener noreferrer"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: inView ? 1 : 0 }}
                    transition={{ duration: 1 }}
                  >
                    Безопасность дорожного движения
                  </motion.a>
                </li>
                <li className="service-item">
                  <motion.a
                    href="https://sferaot.ru/vidy-obucheniya/professionalnoe-obuchenie"
                    target="_blank"
                    rel="noopener noreferrer"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: inView ? 1 : 0 }}
                    transition={{ duration: 1 }}
                  >
                    Профессиональное обучение
                  </motion.a>
                </li>
                <li className="service-item">
                  <motion.a
                    href="https://sferaot.ru/vidy-obucheniya/upravlenie-samokhodnymi-mashinami"
                    target="_blank"
                    rel="noopener noreferrer"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: inView ? 1 : 0 }}
                    transition={{ duration: 1 }}
                  >
                    Обучение на тракторные права
                  </motion.a>
                </li>
                <li className="service-item">
                  <motion.a
                    href="https://sferaot.ru/vidy-obucheniya/okazanie-pervoj-pomoshchi"
                    target="_blank"
                    rel="noopener noreferrer"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: inView ? 1 : 0 }}
                    transition={{ duration: 1 }}
                  >
                    Оказание первой помощи
                  </motion.a>
                </li>
              </ul>
            </div>

            <div className="sub-column">
              <ul className="service-list">
                <li className="service-item">
                  <motion.a
                    href="https://sferaot.ru/vidy-obucheniya/raboty-na-vysote"
                    target="_blank"
                    rel="noopener noreferrer"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: inView ? 1 : 0 }}
                    transition={{ duration: 1 }}
                  >
                    Работы на высоте
                  </motion.a>
                </li>
                <li className="service-item">
                  <motion.a
                    href="https://sferaot.ru/vidy-obucheniya/protivodejstvie-terrorizmu"
                    target="_blank"
                    rel="noopener noreferrer"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: inView ? 1 : 0 }}
                    transition={{ duration: 1 }}
                  >
                    Противодействие терроризму
                  </motion.a>
                </li>
                <li className="service-item">
                  <motion.a
                    href="https://sferaot.ru/vidy-obucheniya/profilaktika-i-protivodejstvie-korruptsii"
                    target="_blank"
                    rel="noopener noreferrer"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: inView ? 1 : 0 }}
                    transition={{ duration: 1 }}
                  >
                    Профилактика и противодействие коррупции
                  </motion.a>
                </li>
                <li className="service-item">
                  <motion.a
                    href="https://sferaot.ru/vidy-obucheniya/profilaktika-i-protivodejstvie-korruptsii"
                    target="_blank"
                    rel="noopener noreferrer"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: inView ? 1 : 0 }}
                    transition={{ duration: 1 }}
                  >
                    Организация закупочной деятельности
                  </motion.a>
                </li>
                <li className="service-item">
                  <motion.a
                    href="https://sferaot.ru/vidy-obucheniya/kadrovoe-deloproizvodstvo"
                    target="_blank"
                    rel="noopener noreferrer"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: inView ? 1 : 0 }}
                    transition={{ duration: 1 }}
                  >
                    Кадровое делопроизводство
                  </motion.a>
                </li>
                <li className="service-item">
                  <motion.a
                    href="https://sferaot.ru/vidy-obucheniya/2sr"
                    target="_blank"
                    rel="noopener noreferrer"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: inView ? 1 : 0 }}
                    transition={{ duration: 1 }}
                  >
                    Строительство
                  </motion.a>
                </li>
                <li className="service-item">
                  <motion.a
                    href="https://sferaot.ru/vidy-obucheniya/dopog-dorozhnaya-perevozka-opasnykh-gruzov"
                    target="_blank"
                    rel="noopener noreferrer"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: inView ? 1 : 0 }}
                    transition={{ duration: 1 }}
                  >
                    ДОПОГ - дорожная перевозка опасных грузов
                  </motion.a>
                </li>
                <li className="service-item">
                  <motion.a
                    href="https://sferaot.ru/vidy-obucheniya/osnovy-glubinnoj-psikhokorrektsii"
                    target="_blank"
                    rel="noopener noreferrer"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: inView ? 1 : 0 }}
                    transition={{ duration: 1 }}
                  >
                    Основы глубинной психокоррекции
                  </motion.a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};