import React from 'react';
import './EcoRunning.css';
import image from '../EcoRunning/ecoimg/6.jpeg';
import image1 from '../EcoRunning/ecoimg/9.png';
import image2 from '../EcoRunning/ecoimg/8.jpeg';
import image3 from '../EcoRunning/ecoimg/5.png';
import image4 from '../EcoRunning/ecoimg/1.jpeg';
import image5 from '../EcoRunning/ecoimg/2.png';
import image6 from '../EcoRunning/ecoimg/3.png';
import image7 from '../EcoRunning/ecoimg/images.jpeg';
import image9 from '../EcoRunning/ecoimg/4.jpeg';
import image10 from '../EcoRunning/ecoimg/7.png';

export const EcoRunning = () => {
  return (
    <div className="marquee-infinite">
      <div>
        <h1 className='sferah1'>Наши Партнеры</h1>
      </div>
      <div className="marquee-content">
        <img className="no-border" src={image} alt="" />
        <img className="no-border" src={image1} alt="" />
        <img className="no-border" src={image2} alt="" />
        <img className="no-border" src={image3} alt="" />
        <img className="no-border" src={image4} alt="" />
        <img className="no-border" src={image5} alt="" />
        <img className="no-border" src={image6} alt="" />
        <img className="no-border" src={image7} alt="" />

        <img className="no-border" src={image9} alt="" />
        <img className="no-border" src={image10} alt="" />
        <img className="no-border" src={image} alt="" />
        <img className="no-border" src={image1} alt="" />
        <img className="no-border" src={image2} alt="" />
        <img className="no-border" src={image3} alt="" />
        <img className="no-border" src={image4} alt="" />
        <img className="no-border" src={image5} alt="" />
        <img className="no-border" src={image6} alt="" />
        <img className="no-border" src={image7} alt="" />

        <img className="no-border" src={image9} alt="" />
        <img className="no-border" src={image10} alt="" />
      </div>
    </div>
  );
};