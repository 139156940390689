import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './SferaServices.css';
import image1 from './1.svg';
import image2 from './2.svg';
import image3 from './3.svg';
import image4 from './4.svg';
import image5 from './5.svg';

export const SferaServices = () => {
  
  const [hoveredItem, setHoveredItem] = useState(null);
  const [hoveredColor, setHoveredColor] = useState(null);
  const [isHoveredItem3, setIsHoveredItem3] = useState(false);
  const [isHoveredItem5, setIsHoveredItem5] = useState(false);

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const handleMouseEnter = (item) => {
    setHoveredItem(item);

    switch (item) {
      case 1:
        setHoveredColor('#b2c6c5');
        break;
      case 2:
        setHoveredColor('#6d887f');
        break;
      case 3:
        setHoveredColor('#4d5253');
        setIsHoveredItem3(true);
        break;
      case 4:
        setHoveredColor('#aeaba3');
        break;
      case 5:
        setHoveredColor('#839889');
        setIsHoveredItem5(true);
        break;
      default:
        setHoveredColor(null);
        break;
    }
  };


  const renderAdditionalContent = (text, color, imagePath, alignRight) => (
    <div className={`additional-content ${alignRight ? 'right-align' : 'left-align'}`}>
      <div className="additional-info my-custom-class" style={{ backgroundColor: color }}>
        <span className='ttdop'>{text}</span>
      </div>
      <img src={imagePath} alt="Cфера охрана труда Симферополь" className={`additional-image ${alignRight ? 'align-right' : 'align-left'}`} />
    </div>
  );

  const itemVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1 } }
  };
  
  const additionalInfoVariants = {
    hidden: { opacity: 0, x: '100%' },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }
  };
  const addition = {
    hidden: { opacity: 0, x: '-100%' },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } }
  };



  return (
    <div
    id ='sfera'
      className={`sfera-container ${hoveredItem ? `item-${hoveredItem}` : ''} ${isHoveredItem3 ? 'item-3-hovered' : ''} ${isHoveredItem5 ? 'item-5-hovered' : ''}`}
      style={{ backgroundColor: hoveredColor }}
      ref={ref}
    >
      <h1 className={`sferah1 ${isHoveredItem3 || isHoveredItem5 ? 'text-white' : ''}`}>
        Услуги учебного центра "Сфера Охраны Труда"
      </h1>

      <motion.div
        className={`item ${hoveredItem === 1 ? 'active' : ''}`}
        onMouseEnter={() => handleMouseEnter(1)}
    
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={itemVariants}
      >
        <motion.span
          className={`mainsferatxt left-align ${hoveredItem === 1 ? 'text-white' : ''}`}
        >
          Аутсорсинг охраны труда
        </motion.span>
        {hoveredItem === 1 &&
          <motion.div
            className="additional-info leftt"
            initial="hidden"
            animate="visible"
            variants={additionalInfoVariants}
          >
            {renderAdditionalContent(
              'Внешняя поддержка и соблюдение требований по охране труда.',
              '#b2c6c5',
              image1,
              false
            )}
          </motion.div>
        }
      </motion.div>

      <motion.div
        className={`item ${hoveredItem === 2 ? 'active' : ''}`}
        onMouseEnter={() => handleMouseEnter(2)}
        
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={itemVariants}
      >
        <motion.div className={`additional-info rightt ${hoveredItem === 2 ? 'text-white' : ''}`} >
          {hoveredItem === 2 &&
            <motion.div
              initial="hidden"
              animate="visible"
              variants={addition}
            >
              {renderAdditionalContent(
                'Анализ опасностей и рисков на рабочих местах.',
                '#6d887f',
                image2,
                true
              )}
            </motion.div>
          }
        </motion.div>
        <motion.span className="mainsferatxt right-align" >
          Оценка профессиональных рисков
        </motion.span>
      </motion.div>

      <motion.div
        className={`item ${hoveredItem === 3 ? 'active' : ''}`}
        onMouseEnter={() => handleMouseEnter(3)}
     
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={itemVariants}
      >
        <motion.span className={`mainsferatxt left-align ${hoveredItem === 3 ? 'text-white' : ''}`} >
          Обучение на тракторные права
        </motion.span>
        {hoveredItem === 3 &&
          <motion.div
            className="additional-info leftt"
            initial="hidden"
            animate="visible"
            variants={additionalInfoVariants}
          >
            {renderAdditionalContent(
              'Обучение безопасному управлению специальной техникой.',
              '#4d5253',
              image3,
              false
            )}
          </motion.div>
        }
      </motion.div>

      <motion.div
        className={`item ${hoveredItem === 4 ? 'active' : ''}`}
        onMouseEnter={() => handleMouseEnter(4)}
    
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={itemVariants}
      >
        <motion.div className={`additional-info rightt ${hoveredItem === 4 ? 'text-white' : ''}`} >
          {hoveredItem === 4 &&
            <motion.div
              initial="hidden"
              animate="visible"
              variants={addition}
            >
              {renderAdditionalContent(
                'Обучение безопасному вождению квадроциклов и тракторов.',
                '#aeaba3',
                image4,
                true
              )}
            </motion.div>
          }
        </motion.div>
        <motion.span className="mainsferatxt right-align" >
          Права на квадроцикл
        </motion.span>
      </motion.div>

      <motion.div
        className={`item ${hoveredItem === 5 ? 'active' : ''}`}
        onMouseEnter={() => handleMouseEnter(5)}
       
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={itemVariants}
      >
        <motion.span className={`mainsferatxt left-align ${hoveredItem === 5 ? 'text-white' : ''}`} >
          Пожарная безопасность
        </motion.span>
        {hoveredItem === 5 &&
          <motion.div
            className="additional-info leftt"
            initial="hidden"
            animate="visible"
            variants={additionalInfoVariants}
          >
            {renderAdditionalContent(
              'Обучение мерам предотвращения пожаров и эвакуации.',
              '#839889',
              image5,
              false
            )}
          </motion.div>
        }
      </motion.div>
    </div>
  );
};