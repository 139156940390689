/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './Contacts.css';
import { useInView } from 'react-intersection-observer';

export const Contacts = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const [mapLoaded, setMapLoaded] = useState(false);
  const phoneNumber = '+79186724399';

  function handleCallClick() {
    var newWindow = window.open(`tel:${phoneNumber}`, '_self');
    newWindow.focus();
  }

  useEffect(() => {
    if (!window.ymaps) {
      const script = document.createElement('script');
      script.src =
        'https://api-maps.yandex.ru/2.1/?apikey=1760bfd3-fa04-47c0-82de-c8d1cedd7219&lang=ru_RU';
      script.async = true;
      script.onload = () => {
        ymaps.load().then(() => setMapLoaded(true));
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    } else {
      ymaps.load().then(() => setMapLoaded(true));
    }
  }, []);

  useEffect(() => {
    if (mapLoaded && window.ymaps) {
      window.ymaps.ready(() => {
        const myMap = new window.ymaps.Map('map', {
          center: [44.971943, 34.115329],
          zoom: 17,
          controls: ['zoomControl', 'typeSelector', 'fullscreenControl'],
        });

        const myPlacemark = new window.ymaps.Placemark([44.971943, 34.115329], {
          hintContent: 'переулок Бокуна, 32',
        });

        myMap.geoObjects.add(myPlacemark);
      });
    }
  }, [mapLoaded]);

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div>
      <div className="four_compon" id="contact" ref={ref}>
        <div className="contactbox">
          <div className="btnnumbr">
            <motion.h1
              className="hcont"
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              variants={itemVariants}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Наши контакты:
            </motion.h1>
            <motion.h3
              className="tell"
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              variants={itemVariants}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <a href="tel:89186724399">8 918 672 43 99</a>
            </motion.h3>
            <motion.h3
              className="tell"
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              variants={itemVariants}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              <a href="tel:89787401782">8 978 740 17 82</a>
            </motion.h3>
            <h3>ИП Борщевская Светлана Павловна</h3>
            <h4>ОГРНИПㅤ ㅤ 314910234506574</h4>
            <h4>ИННㅤ ㅤ 910200391715 </h4>
            <motion.h3
              className="tell"
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              variants={itemVariants}
              transition={{ duration: 0.5, delay: 0.8 }}
            >
              <a href="mailto:lvbor83@mail.ru">lvbor83@mail.ru</a>
            </motion.h3>
            <h3>г.ㅤ Симферополь, переулок Бокуна, 32</h3>
            <button className="call-us-button" onClick={handleCallClick}>
              Связаться с нами
            </button>
          </div>
          <div id="map" className="maps"></div>
        </div>
      </div>
    </div>
  );
};