/* eslint-disable no-undef */
import React  from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './LegalCont.css';

export const LegalCont = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const phoneNumber = '+89788536025';

  function handleCallClick() {
    var newWindow = window.open(`tel:${phoneNumber}`, '_self');
    newWindow.focus();
  }

  

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div ref={ref}>
      <div className='four_com' id='contact'>
        <div className='contactbox'>
          <div className='btnnumbr'>
            <motion.h1
              className='hcont'
              initial='hidden'
              animate={inView ? 'visible' : 'hidden'}
              variants={itemVariants}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Наши контакты:
            </motion.h1>
            <motion.h3
              className='tell'
              initial='hidden'
              animate={inView ? 'visible' : 'hidden'}
              variants={itemVariants}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <a href='tel:89788536025'>+7 (978) 853-60-25 </a>
            </motion.h3>
            <motion.h3
              initial='hidden'
              animate={inView ? 'visible' : 'hidden'}
              variants={itemVariants}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
             Матрёнин Сергей Андреевич
            </motion.h3>
            <motion.h3
              className='tell'
              initial='hidden'
              animate={inView ? 'visible' : 'hidden'}
              variants={itemVariants}
              transition={{ duration: 0.5, delay: 0.8 }}
            >
              <a href='mailto:s.a.matrenin@yandex.ru'>s.a.matrenin@yandex.ru</a>
            </motion.h3>
            <button className='call-us-button' onClick={handleCallClick}>
              Связаться с нами
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};