/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './ContactSF.css';
import { useInView } from 'react-intersection-observer';

export const ContactSF = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const [mapLoaded, setMapLoaded] = useState(false);
  const phoneNumber = '+79787902506';

  function handleCallClick() {
    var newWindow = window.open(`tel:${phoneNumber}`, '_self');
    newWindow.focus();
  }

  useEffect(() => {
    if (!window.ymaps) {
      const script = document.createElement('script');
      script.src =
        'https://api-maps.yandex.ru/2.1/?apikey=1760bfd3-fa04-47c0-82de-c8d1cedd7219&lang=ru_RU';
      script.async = true;
      script.onload = () => {
        ymaps.load().then(() => setMapLoaded(true));
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    } else {
      ymaps.load().then(() => setMapLoaded(true));
    }
  }, []);

  useEffect(() => {
    if (mapLoaded && window.ymaps) {
      window.ymaps.ready(() => {
        const myMap = new window.ymaps.Map('map', {
          center: [44.949111, 34.088999],
          zoom: 17,
          controls: ['zoomControl', 'typeSelector', 'fullscreenControl'],
        });

        const myPlacemark = new window.ymaps.Placemark([44.949111, 34.088999], {
          hintContent: 'ул. Маяковского 14, лит. А,  аудитория 204',
        });

        myMap.geoObjects.add(myPlacemark);
      });
    }
  }, [mapLoaded]);

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div ref={ref}>
      <div className='four_compon' id='contact'>
        <div className='contactbox'>
          <div className='btnnumbr'>
            <motion.h1
              className='hcont'
              initial='hidden'
              animate={inView ? 'visible' : 'hidden'}
              variants={itemVariants}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Наши контакты:
            </motion.h1>
            <motion.h3
              className='tell'
              initial='hidden'
              animate={inView ? 'visible' : 'hidden'}
              variants={itemVariants}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <a href='tel:89787902506'>+7 (978) 790 25 06</a>
            </motion.h3>
            <motion.h3
              initial='hidden'
              animate={inView ? 'visible' : 'hidden'}
              variants={itemVariants}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
             Никифорова Ольга Евгеньевна
            </motion.h3>
            <motion.h3
              className='tell'
              initial='hidden'
              animate={inView ? 'visible' : 'hidden'}
              variants={itemVariants}
              transition={{ duration: 0.5, delay: 0.8 }}
            >
              <a href='mailto:tk@sferaot.ru'>tk@sferaot.ru</a>
            </motion.h3>
            <motion.h3
              className='tell'
              initial='hidden'
              animate={inView ? 'visible' : 'hidden'}
              variants={itemVariants}
              transition={{ duration: 0.5, delay: 1 }}
            >
              <a href='mailto:riski@sferaot.ru'>riski@sferaot.ru</a>
            </motion.h3>
            <motion.h3
              initial='hidden'
              animate={inView ? 'visible' : 'hidden'}
              variants={itemVariants}
              transition={{ duration: 0.5, delay: 1.2 }}
            >
              г. Симферополь, ул. Маяковского 14, лит. А, аудитория 204
            </motion.h3>
            <button className='call-us-button' onClick={handleCallClick}>
              Связаться с нами
            </button>
          </div>
          <div id='map' className='maps'></div>
        </div>
      </div>
    </div>
  );
};