/* eslint-disable no-undef */
import React  from 'react';
import { motion } from 'framer-motion';
import './СontactsAcc.css';
import { useInView } from 'react-intersection-observer';

export const ContactsAcc = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const phoneNumber = '+79785936812';

  function handleCallClick() {
    var newWindow = window.open(`tel:${phoneNumber}`, '_self');
    newWindow.focus();
  }


  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div ref={ref}>
      <div className='four-compon ' id='contact'>
        <div className='contactbox'>
          <div className='btnnumbr'>
            <motion.h1
              className='hcont'
              initial='hidden'
              animate={inView ? 'visible' : 'hidden'}
              variants={itemVariants}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Наши контакты:
            </motion.h1>
            <motion.h3
              className='tell'
              initial='hidden'
              animate={inView ? 'visible' : 'hidden'}
              variants={itemVariants}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <a href='tel:89785936812'>+7 978 593 68 12</a>
            </motion.h3>
            <motion.h3
              initial='hidden'
              animate={inView ? 'visible' : 'hidden'}
              variants={itemVariants}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              Лукьянова Татьяна Николаевна
            </motion.h3>
            <h4>ОГРНИПㅤ ㅤ 314910234409334</h4>
            <h4>ИННㅤ ㅤ 910200375375 </h4>
            <motion.h3
              className='tell'
              initial='hidden'
              animate={inView ? 'visible' : 'hidden'}
              variants={itemVariants}
              transition={{ duration: 0.5, delay: 0.8 }}
            >
              <a href='mailto:79787827212@mail.ru'>79787827212@mail.ru</a>
            </motion.h3>
            <button className='call-us-button' onClick={handleCallClick}>
              Связаться с нами
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
