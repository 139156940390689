import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './PerspectivesEco.css';

export const PerspectivesEco = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.4,
      },
    },
  };

  const flipUnitVariants = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  const cards = [
    {
      title: 'Полная защита от штрафов и проверок на соответствие экологической безопасности',
    },
    {
      title: 'Выгоднее на 70%, чем эколог в штате',
    },
    {
      title: 'Оформление всей документации точно в срок',
    },
    {
      title: 'Бесплатная консультация 24/7 и команда из 15 экспертов',
    },
  ];

  return (
    <div className='mainn_per'>
      <p className="perspectives-title">
        <span className='perspectives-word'>Преимущества </span>
        <span className="perspectives-highlight">работы с нами </span>
      </p>
      <div className="perspectivess-container" ref={ref}>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          className="perspectives-container"
        >
          {cards.map((card, index) => (
            <motion.div
              key={index}
              variants={flipUnitVariants}
              className="perspectives-card"
            >
              <div className="perspectives-card-number">{String(index + 1).padStart(2, '0')}</div>
              <div className="perspectives-card-conten">
                <h2 className="perspectives-card-title">{card.title}</h2>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};