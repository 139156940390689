import React from "react";
import './ServandPrice.css';
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export const ServandPrice = () => {
  const services = [
    { name: "Предоставление устной консультации", price: "500-1000" },
    { name: "Предоставление письменной консультации", price: "1000-2000" },
    { name: "Составление договоров оказания услуг, поставки, купли-продажи, поручительства, аренды и т.д.", price: "3000-7000" },
    { name: "Правовой анализ и рецензирование договоров оказания услуг, поставки, купли-продажи, поручительства, аренды и т.д.", price: "1500-5000" },
    { name: "Составление претензий, ответов на претензии, писем о порядке исполнения договорных обязательств и т.д.", price: "3000-5000" },
    { name: "Составление искового заявления, отзывы на иск с оформлением документации, подтверждающей правовую позицию клиента", price: "5000-10000" },
    { name: "Составление иных процессуальных документов", price: "1500 - 10000" },
    { name: "Представление интересов клиента в органах государственной власти и местного самоуправления по делам об административных правонарушениях", price: "5000 + компенсация расходов" },
    { name: "Представление интересов клиента в органах государственной власти и местного самоуправления по вопросам оказания государственных и муниципальных услуг", price: "5000 + компенсация расходов" },
    { name: "Ознакомление с материалами гражданских, административных дел с проведением правового анализа", price: "5000" },
    { name: "Представление интересов клиента в судах общей юрисдикции и арбитражных судах по делам об административных правонарушениях с подготовкой письменной правовой позиции по делу", price: `ㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤ10 000 за участие в судебном заседании, включая подготовку процессуальных документов, компенсация расходов` },
    { name: "Представление интересов клиента в судах общей юрисдикции и арбитражных судах по гражданским и административным делам", price: "ㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤ10 000 за участие в судебном заседании, включая подготовку процессуальных документов, компенсация расходов" },
  ];

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.4,
      },
    },
  };

  const flipUnitVariants = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <div className="spleg">
        <div className="ap">
        <h1 className="pr_ma_txt">
        Цены и услуги на юридические услуги в Симферополе, Крыму и по всей Росии
      </h1>
      </div>
    <div className="services">
      <motion.div
        ref={ref}
        variants={containerVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        className="services"
      >
        {services.map((service, index) => (
          <motion.div
            key={index}
            variants={flipUnitVariants}
            className="service-ca"
          >
            <div className="service-info">
              <div className="service-na">{service.name}</div>
            </div>
            <div className="service-pri">{service.price}</div>
          </motion.div>
        ))}
      </motion.div>
      <p className="ftt">

*   Не является публичной офертой, окончательная цена зависит от спектра и 
характера услуг.<br />Свяжитесь с нами для определения стоимости услуг 
+79788536025
      </p>
    </div>
    </div>
  );
};