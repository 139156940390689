import {useEffect} from 'react';
import { MainSfera } from './MainSfera/MainSfera';
import { ScrollToTopButton } from '../ScrollToTopButton/ScrollToTopButton';
import { SferaServices } from './SferaServices/SferaServices';
import { DopServices } from './DopServices/DopServices';
import {RunningImage} from './RunningImage/RunningImage';
import { ContactSF } from './ContactSF/ContactSF';
export const Sfera = () => {
  useEffect(() => {
    // Подключаем скрипт здесь
    window.YandexRotorSettings = {
      WaiterEnabled: true,
      FailOnTimeout: false,
      NoJsRedirectsToMain: true
    };
    // Здесь можно выполнить дополнительные действия при монтировании компонента, если это необходимо
  }, []);
  return (
    <div className="main-container">
  <MainSfera />
  <ScrollToTopButton />
  <SferaServices />
  <DopServices />
  <RunningImage />
  <ContactSF />
    </div>
  );
};
