/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import { ReactComponent as Icon } from './arrow_drop_down_FILL0_wght400_GRAD0_opsz48.svg';
import { ReactComponent as Cital } from './method-draw-image (4).svg';

const Navbar = () => {
  const location = useLocation();
  const navRef = useRef();
  const submenuRef = useRef();
  const [navbarState, setNavbarState] = useState({
    isNavCollapsed: true,
    windowWidth: window.innerWidth,
    isDropdownOpen: false,
    isMenuOpen: false,
  });
  const [animationStyle, setAnimationStyle] = useState({});
  const { isNavCollapsed, windowWidth, isDropdownOpen, isMenuOpen } = navbarState;

  const scrollToComponent = (className) => {
    const elements = document.getElementsByClassName(className);
    if (elements.length > 0) {
      elements[0].scrollIntoView({ behavior: 'smooth' });
    }
  };

  const updateAnimationStyle = useCallback(() => {
    if (windowWidth > 990) {
      const linkElement = navRef.current.querySelector(`a[href="${location.pathname}"]`);
      if (linkElement) {
        const targetRect = linkElement.getBoundingClientRect();
        const navRect = navRef.current.getBoundingClientRect();

        setAnimationStyle({
          width: `${targetRect.width}px`,
          left: `${targetRect.left - navRect.left}px`,
        });
      }
    }
  }, [windowWidth, location.pathname]);

  const handleClick = useCallback(
    (event) => {
      const target = event.target;
      const isInSubmenu = target.closest('.submenu');

      if (isInSubmenu) {
        return;
      }

      if (target.tagName === 'svg' || target.parentElement.tagName === 'svg') {
        setNavbarState((prevState) => ({
          ...prevState,
          isDropdownOpen: !prevState.isDropdownOpen,
          isMenuOpen: true,
        }));
      } else if (windowWidth <= 990) {
        setNavbarState((prevState) => ({
          ...prevState,
          isNavCollapsed: true,
        }));
      } else {
        setNavbarState((prevState) => ({
          ...prevState,
          isDropdownOpen: false,
        }));
      }
    },
    [windowWidth]
  );

  const toggleNavCollapse = useCallback(() => {
    setNavbarState((prevState) => ({
      ...prevState,
      isNavCollapsed: !prevState.isNavCollapsed,
    }));
  }, []);

  const linkStyle = useCallback(
    (href) => ({
      color: location.pathname === href ? 'black' : '',
    }),
    [location.pathname]
  );

  useEffect(() => {
    const handleWindowResize = () => {
      setNavbarState((prevState) => ({
        ...prevState,
        windowWidth: window.innerWidth,
      }));
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 990) {
      const linkElement = navRef.current.querySelector(`a[href="${location.pathname}"]`);
      if (linkElement) {
        linkElement.click();
      }
    }
  }, [windowWidth, location.pathname]);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      const isClickInsideMenu = navRef.current.contains(event.target);
      const isClickInsideDropdown = submenuRef.current && submenuRef.current.contains(event.target);
      if (!isClickInsideMenu && !isClickInsideDropdown) {
        setNavbarState((prevState) => ({
          ...prevState,
          isDropdownOpen: false,
          isMenuOpen: false,
        }));
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    updateAnimationStyle();
  }, [windowWidth, location.pathname, updateAnimationStyle]);

  useEffect(() => {
    updateAnimationStyle();
  
    const timeout = setTimeout(() => {
      updateAnimationStyle();
    }, 20);
  
    return () => {
      clearTimeout(timeout);
    };
  }, [ ]);
  
  return (
    <nav className={`navbar ${isNavCollapsed ? '' : 'navbar-expanded'}`} ref={navRef}>
      <div className='cit_div'>
      <Cital className='cital' />
      <h1 className='cital_txt'>
        ЦИТАДЕЛЬ <br />
        КОНСАЛТ
      </h1>
      </div>
 
      
      {windowWidth <= 990 && (
        <button onClick={toggleNavCollapse} className="menu-icon">
          <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48">
            <path d="M120 816v-60h720v60H120Zm0-210v-60h720v60H120Zm0-210v-60h720v60H120Z" />
          </svg>
        </button>
      )}
      
      <ul className={`links ${isNavCollapsed ? 'links-collapsed' : 'links-expanded'}`} onClick={handleClick}>
        
        <li>
          <Link to="/" className="navbar-link" style={linkStyle('/')}>
            Кадровые услуги
            <Icon className="icon" />
            
            {location.pathname === '/' && isDropdownOpen && isMenuOpen && (
              
              <ul ref={submenuRef} className="submenu">
                
                <li>
                  
                  <a href="#services" onClick={() => scrollToComponent('firsttext_services ')}>
                    Услуги
                  </a>
                </li>
                <li>
                  <a href="#prices" onClick={() => scrollToComponent('services')}>
                    Цены
                  </a>
                </li>
                <li>
                  <a href="#advantages" onClick={() => scrollToComponent('advantages')}>
                    Преимущества
                  </a>
                </li>
                <li>
                  <a href="#contacts" onClick={() => scrollToComponent('contactbox')}>
                    Контакты
                  </a>
                </li>
              </ul>
            )}
          </Link>
        </li>
        <li>
          <Link to="/accounting-services" className="navbar-link" style={linkStyle('/accounting-services')}>
            <span className="link-text">Бухгалтерские услуги</span>
            <Icon className="icon" />
            
            {location.pathname === '/accounting-services' && isDropdownOpen && isMenuOpen && (
              <ul className="submenu">
                <li>
                  <a href="#services" onClick={() => scrollToComponent('services-container')}>
                    Услуги
                  </a>
                </li>
                <li>
                  <a href="#prices" onClick={() => scrollToComponent('services')}>
                    Цены
                  </a>
                </li>
                <li>
                  <a href="#advantages" onClick={() => scrollToComponent('pr_ma_txt')}>
                    Преимущества
                  </a>
                </li>
                <li>
                  <a href="#contacts" onClick={() => scrollToComponent('contactbox')}>
                    Контакты
                  </a>
                </li>
              </ul>
            )}
          </Link>
        </li>
        <li>
          <Link to="/work-protection" className="navbar-link" style={linkStyle('/work-protection')}>
            <span className="link-text">Охрана труда</span>
            <Icon className="icon" />
            {location.pathname === '/work-protection' && isDropdownOpen && isMenuOpen && (
              <ul className="submenu">
                <li>
                  <a href="#sfera" onClick={() => scrollToComponent('services-grid')}>
                    Услуги
                  </a>
                </li>
                <li>
                  <a href="#contacts" onClick={() => scrollToComponent('contactbox')}>
                    Контакты
                  </a>
                </li>
              </ul>
            )}
          </Link>
        </li>
        <li>
          <Link to="/legal-services" className="navbar-link" style={linkStyle('/legal-services')}>
            <span className="link-text">Юридические услуги</span>
            <Icon className="icon" />
            {location.pathname === '/legal-services' && isDropdownOpen && isMenuOpen && (
              <ul className="submenu">
                <li>
                  <a href="#services" onClick={() => scrollToComponent('spleg')}>
                    Услуги
                  </a>
                </li>
                <li>
                  <a href="#prices" onClick={() => scrollToComponent('spleg')}>
                    Цены
                  </a>
                </li>
                <li>
                  <a href="#contacts" onClick={() => scrollToComponent('contactbox')}>
                    Контакты
                  </a>
                </li>
              </ul>
            )}
          </Link>
        </li>
        <li>
          <Link to="/eco-support" className="navbar-link" style={linkStyle('/eco-support')}>
            <span className="link-text">Экологическое сопровождение</span>
            <Icon className="icon" />
            {location.pathname === '/eco-support' && isDropdownOpen && isMenuOpen && (
              <ul ref={submenuRef} className="submenu">
                <li>
                  <a href="#services" onClick={() => scrollToComponent('eco-services')}>
                    Услуги
                  </a>
                </li>
                <li>
                  <a href="#advantages" onClick={() => scrollToComponent('mainn_per')}>
                    Преимущества
                  </a>
                </li>
                <li>
                  <a href="#contacts" onClick={() => scrollToComponent('contactbox')}>
                    Контакты
                  </a>
                </li>
                
              </ul>
              
            )}
            
          </Link>
     
        </li>
        
      </ul>
     
      <div className="animation" style={animationStyle}></div>
    
    </nav>
  );
};

export { Navbar };