import React from "react";
import './AccServices.css';
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const services = [
  "Удаленный бухгалтер",
  "Налоговая отчетность",
  "Регистрация ИП",
  "Бухгалтерская отчетность",
  "Ликвидация ООО",
  "Удаленное обслуживание",
  "Регистрация ООО ",
  "Бухгалтерский аутсорсинг",

];
const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const serviceVariants = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

export const AccServices = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.4,
  });
    return (
      <div className="ServicesHR-main" >
        
        <div className="background-hrr">
        <svg xmlns="http://www.w3.org/2000/svg"  preserveAspectRatio="none" viewBox="0 0 500 500" >
    <path fill="#2b2937" d="M1,20 Q200,100 400,100 T500, 100 V370 Q300, 320 100, 380 T0,200 Z" />
</svg>
        </div>
          <div className="firsttext_services">
            <h1 className="fts">
            Что включает в себя услуга <br /> бухгалтерский аутсорсинг
            </h1>
          </div>
          <motion.div
          ref={ref}
          variants={containerVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          className="services-container"
        >
          {services.map((service, index) => (
             <motion.div
             key={index} 
             variants={serviceVariants}
              className="service-card">
              <div className="service-description">{service}</div>
              </motion.div>
          ))}
        </motion.div>
        <p className="f">
        В Крыму и других регионах России все больше компаний выбирают аутсорсинг бухгалтерских услуг для оптимизации бизнес-процессов. Мы предоставляем услуги высококачественного аутсорсинга бухгалтерии в Крыму, Москве и.т.д, включая бухгалтерское сопровождение, составление налоговых отчетов и другие документы. Гарантируем конфиденциальность и защиту данных клиентов. Точная цена услуг будет определена нашим специалистом после обсуждения по телефону или личной встречи, где будут изучены данные вашей компании и объем работ.
        </p>
          </div>
       
      
    );
  };