import { useEffect } from 'react';
import { EcologyMain } from './EcologyMain/EcologyMain';
import { PerspectivesEco } from './PerspectivesEco/PerspectivesEco';
import { EcoServices } from './EcoServices/EcoServices';
import { EcoRunning } from './EcoRunning/EcoRunning';
import { EcoContakt } from './EcoContakt/EcoContakt';
import { ScrollToTopButton } from '../ScrollToTopButton/ScrollToTopButton';
export const Ecology = () => {
  useEffect(() => {
    // Подключаем скрипт здесь
    window.YandexRotorSettings = {
      WaiterEnabled: true,
      FailOnTimeout: false,
      NoJsRedirectsToMain: true
    };
    // Здесь можно выполнить дополнительные действия при монтировании компонента, если это необходимо
  }, []);
  return (
    <div className="main-container">
      
 <EcologyMain />
 <PerspectivesEco />
 <EcoServices />
 <EcoRunning />
 <EcoContakt />
 <ScrollToTopButton />
    </div>
  );
};
