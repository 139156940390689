import React from 'react';
import './RunningImage.css';
import image from './7272541-simferopol.png';
import image1 from './105476_company_logo_1.png';
import image2 from './thumb_WykteRFVSz5JStd.jpg';
import image3 from './Без названия.png';
import image4 from './gorod39I8NH2M.jpg';
import image5 from './Без названия (1).png';
import image6 from './1.jpeg';

export const RunningImage = () => {
  return (
    <div className="marquee-infinite">
        <div >
        <h1 className='sferah1' >Наши Партнеры</h1>
        </div>
      <div className="marquee-content">
        <img src={image} alt="" />
        <img src={image1} alt="" />
        <img src={image2} alt="" />
        <img src={image3} alt="" />
        <img src={image4} alt="" />
        <img src={image5} alt="" />
        <img src={image6} alt="" />
        <img src={image} alt="" />
        <img src={image1} alt="" />
        <img src={image2} alt="" />
        <img src={image3} alt="" />
        <img src={image4} alt="" />
        <img src={image5} alt="" />
        <img src={image6} alt="" />
        <img src={image} alt="" />
        <img src={image1} alt="" />
        <img src={image2} alt="" />
        <img src={image3} alt="" />
        <img src={image4} alt="" />
        <img src={image5} alt="" />
        <img src={image6} alt="" />
      </div>
    </div>
  );
};