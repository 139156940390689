/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import '../../HRServices/Сontacts/Contacts.css';
import croppedLogo from './cropped-ББ_2-removebg-preview.png';
import { useInView } from 'react-intersection-observer';

export const EcoContakt = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const [mapLoaded, setMapLoaded] = useState(false);
  const phoneNumber = '+79780835698';

  function handleCallClick() {
    var newWindow = window.open(`tel:${phoneNumber}`, '_self');
    newWindow.focus();
  }

  useEffect(() => {
    if (!window.ymaps) {
      const script = document.createElement('script');
      script.src =
        'https://api-maps.yandex.ru/2.1/?apikey=1760bfd3-fa04-47c0-82de-c8d1cedd7219&lang=ru_RU';
      script.async = true;
      script.onload = () => {
        ymaps.load().then(() => setMapLoaded(true));
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    } else {
      ymaps.load().then(() => setMapLoaded(true));
    }
  }, []);

  useEffect(() => {
    if (mapLoaded && window.ymaps) {
      window.ymaps.ready(() => {
        const myMap = new window.ymaps.Map('map', {
          center: [44.966058, 34.08837],
          zoom: 17,
          controls: ['zoomControl', 'typeSelector', 'fullscreenControl'],
        });

        const myPlacemark = new window.ymaps.Placemark([44.966058, 34.08837], {
          hintContent: 'улица Гагарина, 14А, Симферополь, Республика Крым',
        });

        myMap.geoObjects.add(myPlacemark);
      });
    }
  }, [mapLoaded]);

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div ref={ref}>
      <div className="four_compon" id="contact">
        <div className="contactbox">
          <div className="btnnumbr">
            <motion.h1
              className="hcont"
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              variants={itemVariants}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Наши контакты:
            </motion.h1>
            <div className="ec-im">
              <img src={croppedLogo} alt="" />
            </div>
            <motion.h3
              className="tell"
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              variants={itemVariants}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <a href="tel:89780835698">8 978 083 56 98</a>
            </motion.h3>
            <motion.h3
              className="tell"
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              variants={itemVariants}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              <a href="tel:89786835726">8 978 683 57 26</a>
            </motion.h3>
            <motion.h3
              className="tell"
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              variants={itemVariants}
              transition={{ duration: 0.5, delay: 0.8 }}
            >
              <a href="mailto:eco@bisbur.ru">eco@bisbur.ru</a>
            </motion.h3>
            <motion.h3
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              variants={itemVariants}
              transition={{ duration: 0.5, delay: 1 }}
            >
              улица Гагарина, 14А, Симферополь
            </motion.h3>
            <button className="call-us-button" onClick={handleCallClick}>
              Связаться с нами
            </button>
          </div>
          <div id="map" className="maps"></div>
        </div>
      </div>
    </div>
  );
};