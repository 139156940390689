import React, { useCallback, memo } from 'react';
import './AccountantMain.css';
import { motion } from 'framer-motion';

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const transition = {
  duration: 2,
  yoyo: Infinity, 
};


const AnimatedCard = memo(({ animation, digit }) => {
  return(
    <div className={`flipCard ${animation}`}>
      <span>{digit}</span>
    </div>
  );
});

const StaticCard = memo(({ position, digit }) => {
  return(
    <div className={position}>
      <span>{digit}</span>
    </div>
  );
});

const FlipUnitContainer = memo(({ digit, shuffle }) => {
  let currentDigit = digit;
  let previousDigit = digit - 1;

  if ( previousDigit < 0 ) 
    previousDigit = 0;

  const digit1 = shuffle ? previousDigit : currentDigit;
  const digit2 = !shuffle ? previousDigit : currentDigit;

  const animation1 = shuffle ? 'fold' : 'unfold';
  const animation2 = !shuffle ? 'fold' : 'unfold';

  return(
    <div className={'flipUnitContainer'}>
      <StaticCard 
        position={'upperCard'} 
        digit={currentDigit} 
        />
      <StaticCard 
        position={'lowerCard'} 
        digit={previousDigit} 
        />
      <AnimatedCard 
        digit={digit1}
        animation={animation1}
        />
      <AnimatedCard 
        digit={digit2}
        animation={animation2}
        />
    </div>
  );
});///////////////////
export const Accountant = () => {
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.4,
      },
    },
  };
  const flipUnitVariants = {
    hidden: {
      opacity: 0,
      y: 20, 
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };
  const EXPERIENCE_START = 15;
  const EXPERIENCE_END = 20; 
  const COMPANIES_START = 47;
  const COMPANIES_END = 52; 
  const CLIENTS_START = 994;
  const CLIENTS_END = 1000;


  const [experience, setExperience] = React.useState(EXPERIENCE_START);
  const [experienceShuffle, setExperienceShuffle] = React.useState(false);

  const [companies, setCompanies] = React.useState(COMPANIES_START);
  const [companiesShuffle, setCompaniesShuffle] = React.useState(false);

  const [clients, setClients] = React.useState(CLIENTS_START);
  const [clientsShuffle, setClientsShuffle] = React.useState(false);

  const incrementExperience = useCallback(() => {
    if (experience < EXPERIENCE_END) {
      setExperienceShuffle(!experienceShuffle);
      return experience + 1;
    }
    return experience;
  }, [experience, experienceShuffle]);

  const incrementCompanies = useCallback(() => {
    if (companies < COMPANIES_END) {
      setCompaniesShuffle(!companiesShuffle);
      return companies + 1;
    }
    return companies;
  }, [companies, companiesShuffle]);

  const incrementClients = useCallback(() => {
    if (clients < CLIENTS_END) {
      setClientsShuffle(!clientsShuffle);
      return clients + 1;
    }
    return clients;
  }, [clients, clientsShuffle]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setExperience(incrementExperience);
      setCompanies(incrementCompanies);
      setClients(incrementClients);
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, [incrementExperience, incrementCompanies, incrementClients]);
  const handleCallButtonClick = () => {
    window.location.href = "tel:+79785936812";
  };

  return (
    <div className="accountant-container">
       <div>
        <motion.h1 
        className='main-textt'
        variants={variants}
        initial='hidden'
        animate='visible'
        transition={transition}
      >
       АУТСОРСИНГ БУХГАЛТЕСКИХ УСЛУГ
      </motion.h1>
      <motion.p
        className='sub-text'
        variants={variants}
        initial='hidden'
        animate='visible'
        transition={{...transition, delay: 0.5}}
      >
        Возьмем на себя бухгалтерский и налоговый учет, чтобы вы могли уделить время развитию бизнеса
      </motion.p>
      <div className='line'>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 80">
  <path fill="none" stroke="rgb(182, 182, 182)"  d="M0,50 Q240,10 480,50 Q720,90 960,50 Q1200,10 1440,50"></path>
</svg>
      </div>
      <motion.div
  className='opit'
  variants={containerVariants}
  initial='hidden'
  animate='visible'
>
  <motion.div className='module' variants={flipUnitVariants}>
    <motion.div className='number'>
      <FlipUnitContainer digit={experience} shuffle={experienceShuffle} />
    </motion.div>
    <div className='text'>более лет опыта</div>
  </motion.div>
  <motion.div className='module' variants={flipUnitVariants}>
    <motion.div className='number'>
      <FlipUnitContainer digit={companies} shuffle={companiesShuffle} />
    </motion.div>
    <div className='text'>более предприятий</div>
  </motion.div>
  <motion.div className='module' variants={flipUnitVariants}>
    <motion.div className='number'>
      <FlipUnitContainer digit={clients} shuffle={clientsShuffle} />
    </motion.div>
    <div className='text'>более довольных клиентов</div>
  </motion.div>
</motion.div>
        </div>
        <div className='img-containerr'>
        <svg  xmlns="http://www.w3.org/2000/svg"  width="731.38037" height="601.75468" viewBox="0 0 806.63176 698.76576">

<g>
 <title>background</title>
</g>
<g>
 <title>Layer 1</title>
 <path id="svg_1" fill="#fff" d="m388.893696,139.999995l-253,0a23.02589,23.02589 0 0 1 -23,-23l0,-94a23.0262,23.0262 0 0 1 23,-23l253,0a23.02619,23.02619 0 0 1 23,23l0,94a23.02588,23.02588 0 0 1 -23,23z"/>
 <path id="svg_2" fill="#3f3d56" d="m388.893696,139.999995l-253,0a23.02589,23.02589 0 0 1 -23,-23l0,-94a23.0262,23.0262 0 0 1 23,-23l253,0a23.02619,23.02619 0 0 1 23,23l0,94a23.02588,23.02588 0 0 1 -23,23zm-253,-138a21.02359,21.02359 0 0 0 -21,21l0,94a21.0239,21.0239 0 0 0 21,21l253,0a21.0239,21.0239 0 0 0 21,-21l0,-94a21.02359,21.02359 0 0 0 -21,-21l-253,0z"/>
 <path id="svg_3" fill="#fff" d="m484.627286,152.792825l-134.56983,0a11.11481,11.11481 0 0 1 -2.51953,-0.31934a11.24843,11.24843 0 0 1 -8.6206,-10.9707l0,-45.74024a11.29752,11.29752 0 0 1 11.29,-11.27978l134.41996,0a11.14386,11.14386 0 0 1 5.20263,1.27344a10.81246,10.81246 0 0 1 1.8042,1.17724a11.16539,11.16539 0 0 1 4.28321,8.8291l0,45.74024a11.30253,11.30253 0 0 1 -11.29004,11.29004z"/>
 <path id="svg_4" fill="#e6e6e6" d="m475.607326,151.792825q9.165,-22.275 19.31,-44.11l0,24.68a119.89862,119.89862 0 0 1 -15.92,19.43l-3.39,0z"/>
 <path id="svg_5" fill="#e6e6e6" d="m491.007346,87.712805q-18.135,32.535 -37.95,64.08l-2.3,0q20.145,-32.04 38.61,-65.15a9.82915,9.82915 0 0 1 1.64,1.07z"/>
 <path id="svg_6" fill="#e6e6e6" d="m402.287316,111.392795a407.71414,407.71414 0 0 1 -26.11,40.4l-13.71,0q13.47007,-16.41 26.96,-32.82c2.58,-3.15 5.38,-6.45 9.25,-7.73l3.61,0.15z"/>
 <path id="svg_7" fill="#4d5253" d="m384.067346,110.152805c-1.43005,1.49 -2.80005,3.04 -4.09,4.65c-2.84,3.42 -5.64,6.87 -8.45,10.31c-1.75,2.14 -3.5,4.28 -5.23,6.42a12.77016,12.77016 0 0 1 -1.56,-1.18a13.99258,13.99258 0 0 1 17.88,-21.52a12.89785,12.89785 0 0 1 1.45,1.32z"/>
 <path id="svg_8" fill="#e6e6e6" d="m461.397306,118.872775l-26.28,-9.61l-13.05,-4.77c-4.25,-1.55 -8.49,-3.32 -12.88,-4.44a20.30554,20.30554 0 0 0 -11.66,0.15a26.80773,26.80773 0 0 0 -9.09,5.64q-1.74006,1.605 -3.37,3.31c-1.43005,1.49 -2.80005,3.04 -4.09,4.65c-2.84,3.42 -5.64,6.87 -8.45,10.31c-1.75,2.14 -3.5,4.28 -5.23,6.42q-8.67,10.605 -17.24,21.26a10.02224,10.02224 0 0 1 -2.28,-0.29q8.94,-11.1 17.96,-22.15c0.69,-0.86 1.4,-1.73 2.1,-2.59q4.395,-5.385 8.8,-10.76c2.27,-2.77 4.52,-5.56 6.98,-8.17c0.22,-0.24 0.44,-0.47 0.67,-0.71c4.99,-5.16 11.22,-9.72 18.68,-9.84c4.39,-0.07 8.63,1.32 12.71,2.79c4.39,1.59 8.77,3.21 13.16,4.82l26.46,9.68l6.62,2.42005c1.17,0.41999 0.67,2.31995 -0.52,1.87995z"/>
 <path id="svg_9" fill="#3f3d56" d="m484.627286,152.792825l-134.56983,0a11.11481,11.11481 0 0 1 -2.51953,-0.31934a11.24843,11.24843 0 0 1 -8.6206,-10.9707l0,-45.74024a11.29752,11.29752 0 0 1 11.29,-11.27978l134.41996,0a11.14386,11.14386 0 0 1 5.20263,1.27344a10.81246,10.81246 0 0 1 1.8042,1.17724a11.16539,11.16539 0 0 1 4.28321,8.8291l0,45.74024a11.30253,11.30253 0 0 1 -11.29004,11.29004zm-134.41992,-66.31006a9.29554,9.29554 0 0 0 -9.29,9.27978l0,45.74024a9.25725,9.25725 0 0 0 7.09472,9.02832a9.13335,9.13335 0 0 0 2.05762,0.26172l134.55758,0a9.30086,9.30086 0 0 0 9.29,-9.29l0,-45.74028a9.18462,9.18462 0 0 0 -3.52784,-7.26367a8.8598,8.8598 0 0 0 -1.481,-0.96777a9.16856,9.16856 0 0 0 -4.28125,-1.04834l-134.41983,0z"/>
 <path id="svg_10" fill="#fff" d="m216.999996,196.488185l-194,0a23.02588,23.02588 0 0 1 -23,-23l0,-34a23.02619,23.02619 0 0 1 23,-23l194,0a23.02619,23.02619 0 0 1 23,23l0,34a23.02588,23.02588 0 0 1 -23,23z"/>
 <path id="svg_11" fill="#3f3d56" d="m216.999996,196.488185l-194,0a23.02588,23.02588 0 0 1 -23,-23l0,-34a23.02619,23.02619 0 0 1 23,-23l194,0a23.02619,23.02619 0 0 1 23,23l0,34a23.02588,23.02588 0 0 1 -23,23zm-194,-78a21.02359,21.02359 0 0 0 -21,21l0,34a21.0239,21.0239 0 0 0 21,21l194,0a21.0239,21.0239 0 0 0 21,-21l0,-34a21.02359,21.02359 0 0 0 -21,-21l-194,0z"/>
 <path id="svg_12" fill="#4d5253" d="m79.962996,177.364225l-31.1883,0a6.295,6.295 0 0 1 -6.288,-6.288l0,-29.17612a6.295,6.295 0 0 1 6.288,-6.288l31.1883,0a6.295,6.295 0 0 1 6.288,6.288l0,29.17616a6.295,6.295 0 0 1 -6.288,6.28796z"/>
 <path id="svg_13" fill="#b2c6c5" d="m134.594186,176.364225l-31.18834,0a6.295,6.295 0 0 1 -6.288,-6.288l0,-29.17612a6.295,6.295 0 0 1 6.288,-6.288l31.18834,0a6.295,6.295 0 0 1 6.288,6.288l0,29.17616a6.295,6.295 0 0 1 -6.288,6.28796z"/>
 <path id="svg_14" fill="#e6e6e6" d="m191.225306,177.364225l-31.18831,0a6.295,6.295 0 0 1 -6.288,-6.288l0,-29.17612a6.295,6.295 0 0 1 6.288,-6.288l31.18831,0a6.295,6.295 0 0 1 6.288,6.288l0,29.17616a6.295,6.295 0 0 1 -6.288,6.28796z"/>
 <path id="svg_15" fill="#ccc" d="m274.728346,55.488185l-40,0a4,4 0 0 1 0,-8l40,0a4,4 0 0 1 0,8z"/>
 <path id="svg_16" fill="#ccc" d="m360.728346,77.488185l-127,0a4,4 0 0 1 0,-8l127,0a4,4 0 0 1 0,8z"/>
 <circle id="svg_17" fill="#4d5253" r="26.8189" cy="62.48819" cx="187.22441"/>
 <path id="svg_18" fill="#fff" d="m478.999996,313.488185l-253,0a23.02588,23.02588 0 0 1 -23,-23l0,-94a23.02619,23.02619 0 0 1 23,-23l253,0a23.02619,23.02619 0 0 1 23,23l0,94a23.02588,23.02588 0 0 1 -23,23z"/>
 <path id="svg_19" fill="#3f3d56" d="m478.999996,313.488185l-253,0a23.02588,23.02588 0 0 1 -23,-23l0,-94a23.02619,23.02619 0 0 1 23,-23l253,0a23.02619,23.02619 0 0 1 23,23l0,94a23.02588,23.02588 0 0 1 -23,23zm-253,-138a21.02359,21.02359 0 0 0 -21,21l0,94a21.0239,21.0239 0 0 0 21,21l253,0a21.0239,21.0239 0 0 0 21,-21l0,-94a21.02359,21.02359 0 0 0 -21,-21l-253,0z"/>
 <path id="svg_20" fill="#4d5253" d="m324.538656,210.731775l-42.49665,0a4.05826,4.05826 0 1 1 0,-8.11651l42.49665,0a4.05826,4.05826 0 0 1 0,8.11651z"/>
 <path id="svg_21" fill="#e6e6e6" d="m419.802066,227.330135l-137.76006,0a4.05825,4.05825 0 1 1 0,-8.1165l137.76006,0a4.05825,4.05825 0 0 1 0,8.1165z"/>
 <path id="svg_22" fill="#e6e6e6" d="m419.802066,246.382815l-137.76006,0a4.05825,4.05825 0 1 1 0,-8.1165l137.76006,0a4.05825,4.05825 0 0 1 0,8.1165z"/>
 <path id="svg_23" fill="#e6e6e6" d="m419.802066,265.435505l-137.76006,0a4.05826,4.05826 0 1 1 0,-8.11651l137.76006,0a4.05826,4.05826 0 0 1 0,8.11651z"/>
 <path id="svg_24" fill="#e6e6e6" d="m419.802066,284.488185l-137.76006,0a4.05825,4.05825 0 1 1 0,-8.1165l137.76006,0a4.05825,4.05825 0 0 1 0,8.1165z"/>
 <polygon id="svg_25" fill="#ffb6b6" points="598.507 584.918 584.621 584.917 578.014 531.353 598.51 531.354 598.507 584.918"/>
 <path id="svg_26" fill="#2f2e41" d="m598.996056,599.404005l-42.70332,-0.00161l0,-0.54006a16.62223,16.62223 0 0 1 16.62132,-16.621l0.001,0l7.8003,-5.91772l14.55365,5.91862l3.72772,0.00013l-0.00067,17.16164z"/>
 <polygon id="svg_27" fill="#ffb6b6" points="695.84 559.374 684.481 567.36 648.267 527.345 665.033 515.556 695.84 559.374"/>
 <path id="svg_28" fill="#2f2e41" d="m704.573406,570.942455l-34.9327,24.56175l-0.31064,-0.44177a16.62222,16.62222 0 0 1 4.03594,-23.15683l0.00084,-0.0006l2.97685,-9.32751l15.30945,-3.52981l3.04939,-2.14409l9.87087,14.03886z"/>
 <path id="svg_29" fill="#2f2e41" d="m653.834826,318.441105l1.15381,2.465c7.58446,16.20317 9.5237,34.316 6.57629,51.962a2.94355,2.94355 0 0 0 0.11755,2.09368c1.82792,2.144 -1.03991,11.45884 -2.68377,16.66l-1.48165,9.1975s2.45684,8.54206 -1.27491,7.91428s1.82525,8.48365 -1.73438,10.76624l-9.234,57.32155l38.52491,63.49476l-14.26849,20.68922l-57.074,-73.48269l-0.71343,-66.34845l-8.56109,53.50681l-0.71342,84.18406l-25.95789,-2.75935l-9.5104,-81.61753l9.07159,-112.87368l14.26849,-48.93978l63.49479,5.76638z"/>
 <path id="svg_30" fill="#ffb6b6" d="m493.073596,298.844995a7.74427,7.74427 0 0 1 10.77619,-0.74019a7.42985,7.42985 0 0 1 0.85424,0.88247l25.43912,-9.09873l9.15854,11.28393l-36.196,8.69647a7.72281,7.72281 0 0 1 -9.41777,-0.44719a7.39069,7.39069 0 0 1 -0.74927,-10.42514q0.06642,-0.07672 0.13495,-0.15162z"/>
 <path id="svg_31" fill="#b2c6c5" d="m636.999996,172.488185l-21,1l-10.04211,17.34842l-14.26848,4.28054l-12.12822,29.96382l7.4388,93.40722s1.49108,5.64973 -1.25446,4.82486s-1.62644,6.25555 -1.62644,6.25555l-10.1191,38.91959l92,-8l-5.10844,-41.23523s7.10241,0.2292 2.10844,-4.76477s-7.42261,-3.40309 -2.62525,-9.3071s5.51078,-10.18455 5.51078,-10.18455l2.14027,-29.25039l3.52846,-41.16536a39.55463,39.55463 0 0 0 -14.94323,-34.45759l0,0l-19.61102,-17.63501z"/>
 <path id="svg_32" fill="#ffb6b6" d="m685.563346,375.390545a7.74427,7.74427 0 0 0 -1.918,-10.62994a7.43,7.43 0 0 0 -1.0646,-0.61246l2.60044,-26.89189l-13.18124,-6.12054l0.42055,37.22371a7.72279,7.72279 0 0 0 2.737,9.02235a7.39069,7.39069 0 0 0 10.29177,-1.82327q0.05822,-0.08317 0.11408,-0.16796z"/>
 <path id="svg_33" fill="#b2c6c5" d="m664.672846,200.786955s30.24085,72.03781 29.32715,99.70123l-10,51l-14,-1l1,-46c5.10834,-5.22256 -16.27087,-34.71918 -23.318,-55.47778l16.99085,-48.22345z"/>
 <path id="svg_34" fill="#b2c6c5" d="m592.116256,193.403725s-37.54185,68.35894 -52.07994,88.46459l-11.03632,8.61987l0,14l25.66149,0.56643s38.16819,-31.74738 36.74134,-47.44271s0.71343,-64.20818 0.71343,-64.20818z"/>
 <circle id="svg_35" fill="#ffb6b6" r="26.63173" cy="140.03937" cx="625.11023"/>
 <path id="svg_36" fill="#3d2712" d="m641.400676,159.488185c-7.6423,-0.25074 -8.33652,-2.26936 -15.97882,-2.5201c-2.43339,-0.07984 -5.30489,-0.41335 -6.48134,-2.545c-0.83791,-1.51819 -0.45234,-3.43 0.28508,-4.99943a45.6583,45.6583 0 0 0 2.35431,-4.63127a8.08891,8.08891 0 0 0 -8.01463,-10.66433c-2.32567,0.1005 -4.50985,1.19416 -6.82909,1.394c-3.18069,0.27414 -10.214,-10.22767 -8.7496,-13.06447c1.42546,-2.76135 4.29416,-4.8447 4.745,-7.91939c0.2949,-2.011 -0.53521,-4.00913 -0.64864,-6.03845c-0.16818,-3.00895 7.45914,3.77557 6.06227,1.10521c-3.42655,-6.55054 28.86321,-14.67148 34.17919,-6.46633c1.98678,3.06661 5.52756,2.85744 8.68347,4.69913c2.06843,1.20706 7.29142,5.37134 8.4788,7.96687a20.20813,20.20813 0 0 1 1.51247,8.36143c0.04777,13.31425 -1.98065,25.56131 -12.72,33.43136"/>
 <path id="svg_37" fill="#f2f2f2" d="m510.942016,593.856885a48.67532,48.67532 0 0 1 -39.25924,-14.64528c-6.59053,-6.94854 -11.11322,-15.89234 -15.48708,-24.54163c-1.2732,-2.51693 -2.58928,-5.11971 -3.9282,-7.61425a15.53413,15.53413 0 0 1 1.61174,-17.33178a15.27245,15.27245 0 0 1 16.303,-5.20878l0.63822,0.18437l5.74416,22.4141c0.85694,-3.34323 2.69631,-10.87591 3.85784,-18.25611l0.09758,-0.61814l0.572,0.25474a78.13223,78.13223 0 0 1 16.78623,10.11536a80.52866,80.52866 0 0 1 30.11215,60.64308l0.0152,0.697l-17.0636,-6.09268z"/>
 <path id="svg_38" fill="#cacaca" d="m730.189686,601.447335l-278.75,0.30733a1.19068,1.19068 0 0 1 0,-2.38137l278.75,-0.30733a1.19069,1.19069 0 1 1 0,2.38137z"/>
</g>
</svg>

<motion.div
  className='socbutt'
  variants={variants}
  initial='hidden'
  animate='visible'
  transition={transition}
>
  <motion.button
    className="round-buttonn"
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 1.9 }}
    onClick={handleCallButtonClick}
  >
    Получить консультацию
  </motion.button>

</motion.div>
        </div>
      
    </div>
  );
};