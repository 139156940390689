import React, { useCallback, memo } from 'react';
import './EcologyMain.css';
import { motion } from 'framer-motion';

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const transition = {
  duration: 2,
  yoyo: Infinity, 
};


const AnimatedCard = memo(({ animation, digit }) => {
  return(
    <div className={`flipCard ${animation}`}>
      <span>{digit}</span>
    </div>
  );
});

const StaticCard = memo(({ position, digit }) => {
  return(
    <div className={position}>
      <span>{digit}</span>
    </div>
  );
});

const FlipUnitContainer = memo(({ digit, shuffle }) => {
  let currentDigit = digit;
  let previousDigit = digit - 1;

  if ( previousDigit < 0 ) 
    previousDigit = 0;

  const digit1 = shuffle ? previousDigit : currentDigit;
  const digit2 = !shuffle ? previousDigit : currentDigit;

  const animation1 = shuffle ? 'fold' : 'unfold';
  const animation2 = !shuffle ? 'fold' : 'unfold';

  return(
    <div className={'flipUnitContainer'}>
      <StaticCard 
        position={'upperCard'} 
        digit={currentDigit} 
        />
      <StaticCard 
        position={'lowerCard'} 
        digit={previousDigit} 
        />
      <AnimatedCard 
        digit={digit1}
        animation={animation1}
        />
      <AnimatedCard 
        digit={digit2}
        animation={animation2}
        />
    </div>
  );
});///////////////////
export const EcologyMain = () => {
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.4,
      },
    },
  };
  const flipUnitVariants = {
    hidden: {
      opacity: 0,
      y: 20, 
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };
  const EXPERIENCE_START = 1;
  const EXPERIENCE_END = 5; 
  const COMPANIES_START = 16;
  const COMPANIES_END = 20; 
  const CLIENTS_START = 472;
  const CLIENTS_END = 476;


  const [experience, setExperience] = React.useState(EXPERIENCE_START);
  const [experienceShuffle, setExperienceShuffle] = React.useState(false);

  const [companies, setCompanies] = React.useState(COMPANIES_START);
  const [companiesShuffle, setCompaniesShuffle] = React.useState(false);

  const [clients, setClients] = React.useState(CLIENTS_START);
  const [clientsShuffle, setClientsShuffle] = React.useState(false);

  const incrementExperience = useCallback(() => {
    if (experience < EXPERIENCE_END) {
      setExperienceShuffle(!experienceShuffle);
      return experience + 1;
    }
    return experience;
  }, [experience, experienceShuffle]);

  const incrementCompanies = useCallback(() => {
    if (companies < COMPANIES_END) {
      setCompaniesShuffle(!companiesShuffle);
      return companies + 1;
    }
    return companies;
  }, [companies, companiesShuffle]);

  const incrementClients = useCallback(() => {
    if (clients < CLIENTS_END) {
      setClientsShuffle(!clientsShuffle);
      return clients + 1;
    }
    return clients;
  }, [clients, clientsShuffle]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setExperience(incrementExperience);
      setCompanies(incrementCompanies);
      setClients(incrementClients);
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, [incrementExperience, incrementCompanies, incrementClients]);
  const handleCallButtonClick = () => {
    window.location.href = "tel:89780835698";
  };

  return (
    <div className="ecclogy-container">
       <div>
        <motion.h1 
        className='main-t'
        variants={variants}
        initial='hidden'
        animate='visible'
        transition={transition}
      >
       СОПРОВОЖДЕНИЕ ПРИРОДООХРАННОЙ ДЕЯТЕЛЬНОСТИ
      </motion.h1>
      <motion.p
        className='sub-text'
        variants={variants}
        initial='hidden'
        animate='visible'
        transition={{...transition, delay: 0.5}}
      >
       Разрабатываем проекты, выполняем отчётность, сокращаем расходы на экологию
      </motion.p>
      <div className='line'>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 80">
  <path fill="none" stroke="rgb(182, 182, 182)"  d="M0,50 Q240,10 480,50 Q720,90 960,50 Q1200,10 1440,50"></path>
</svg>
      </div>
      <motion.div
  className='opit'
  variants={containerVariants}
  initial='hidden'
  animate='visible'
>
  <motion.div className='mod' variants={flipUnitVariants}>
    <motion.div className='number'>
      <FlipUnitContainer digit={experience} shuffle={experienceShuffle} />
    </motion.div>
    <div className='text'>более лет опыта</div>
  </motion.div>
  <motion.div className='mod' variants={flipUnitVariants}>
    <motion.div className='number'>
      <FlipUnitContainer digit={companies} shuffle={companiesShuffle} />
    </motion.div>
    <div className='text'>более предприятий</div>
  </motion.div>
  <motion.div className='mod' variants={flipUnitVariants}>
    <motion.div className='number'>
      <FlipUnitContainer digit={clients} shuffle={clientsShuffle} />
    </motion.div>
    <div className='text'>более довольных клиентов</div>
  </motion.div>
</motion.div>
        </div>
        <div className='img-co'>
        <svg width="717.67004" height="544.56279" xmlns="http://www.w3.org/2000/svg " viewBox="0 0 717.67004 544.56279">

 <g>
  <title>background</title>
  <rect fill="none" id="canvas_background" height="402" width="582" y="-1" x="-1"/>
 </g>
 <g>
  <title>Layer 1</title>
  <path id="svg_1" fill="#2f2e41" d="m287.185552,52.527157c-8.74377,15.063 7.72065,40.4432 -8.87275,45.71354l1.367,-1.367c10.0162,-12.32036 -10.919,-38.397 -0.90274,-50.71737a30.10219,30.10219 0 0 0 3.97967,-5.91559"/>
  <path id="svg_2" fill="#ffb8b8" d="m369.071582,273.512557a10.49546,10.49546 0 0 0 -10.19069,-12.456l-21.61329,-62.79317l-11.96325,15.2489l22.85167,58.6731a10.55234,10.55234 0 0 0 20.91553,1.32714l0.00003,0.00003z"/>
  <path id="svg_3" fill="#2b2937" d="m274.065892,121.905257s-0.67244,-22.7304 19.65389,-9.70908s64.58218,134.99911 64.58218,134.99911l-22.0235,11.9154l-49.99659,-111.62352l-12.21598,-25.58191z"/>
  <polygon id="svg_4" fill="#ffb6b6" points="334.034 511.488 323.884 515.825 302.324 478.74 317.305 472.338 334.034 511.488"/>
  <path id="svg_5" fill="#2f2e41" d="m346.905522,526.784157l-7.27882,3.11037l-4.2358,-6.31688l-0.391,8.294l-19.30517,8.24944a4.71923,4.71923 0 0 1 -5.84632,-6.857l10.86679,-17.235l-2.96876,-6.94743l16.62909,-5.96133l12.52999,23.66383z"/>
  <polygon id="svg_6" fill="#ffb6b6" points="174.924 524.067 163.9 523.528 160.731 480.749 177.003 481.544 174.924 524.067"/>
  <path id="svg_7" fill="#2f2e41" d="m179.809552,543.452677l-7.9061,-0.38639l-1.0464,-7.53326l-3.9791,7.28765l-20.96886,-1.0248a4.71924,4.71924 0 0 1 -2.25849,-8.72338l17.3103,-10.74639l0.3688,-7.54615l17.56156,1.91205l0.91829,26.76067z"/>
  <path id="svg_8" fill="#f2f2f2" d="m499.752022,517.250807a3.56386,3.56386 0 0 0 2.52393,-6.20136c-0.09328,-0.35631 -0.1602,-0.61214 -0.25341,-0.9685a9.57475,9.57475 0 0 1 17.74853,-0.33644c4.31772,9.84579 9.88142,20.02805 7.26661,30.94266c17.594,-38.22733 11.03667,-85.50773 -15.81028,-117.80021c-7.37364,-3.97084 -13.38731,-11.09312 -15.01445,-19.41415c3.642,1.19546 8.14,-1.80049 5.37412,-5.59841c-1.197,-1.45023 -2.40544,-2.88879 -3.60237,-4.33908c13.65013,-14.81754 30.30051,8.10753 15.31045,28.074a114.26429,114.26429 0 0 1 13.024,19.95055a48.04331,48.04331 0 0 1 2.65565,-22.34155c2.68222,-6.64886 7.77474,-12.27553 12.25158,-18.044c5.443,-7.02861 16.81306,-3.91815 17.6387,4.92755c-5.06867,1.71965 -5.65929,9.07055 0.16646,10.02036c-1.36654,16.48349 -11.98172,31.72082 -26.73177,39.08465a114.70968,114.70968 0 0 1 6.72483,47.88518c2.43494,-19.011 22.77131,-23.8125 36.94436,-31.49419a5.54912,5.54912 0 0 1 8.516,5.53977a32.05186,32.05186 0 0 0 -5.58948,3.01718a4.84411,4.84411 0 0 0 2.33454,8.8211c-7.01111,17.83933 -25.46154,29.94946 -44.52854,30.06384a117.92872,117.92872 0 0 1 -7.70134,23.201l-28.29843,0.26948c-0.10449,-0.31345 -0.19885,-0.63723 -0.29316,-0.95078a32.15277,32.15277 0 0 0 7.82585,-0.5411c-7.00816,-8.139 -12.40748,-12.27406 -8.48327,-23.76706l0.00089,-0.00049z"/>
  <path id="svg_9" fill="#2f2e41" d="m202.773732,245.985327s-22.419,61.09728 -22.78123,67.93447s-6.75591,93.29728 -12.11811,98.3209s-21.75591,82.90815 -9,89s53,-4 53,-4l14,-92l4.75591,-70.48371s14.4788,165.97637 40.7394,165.48819s58.2606,-10.48819 58.2606,-10.48819l-36.75591,-100.51629s0.51968,-118.99873 -8,-143s-82.10066,-0.25537 -82.10066,-0.25537z"/>
  <path id="svg_10" fill="#2b2937" d="m269.298082,88.574807l-30.02424,2.75011l-4.8369,14.84778s-25.12627,13.799 -19.56255,20.068s-1.24409,48.51629 -1.24409,48.51629l1.53763,34.29291s-1.65173,23.75888 -6.59468,29.233s-17.87482,18.89736 -8.73744,12.47537c32.03858,-22.51755 88.03858,-3.51755 88.03858,-3.51755l-8,-49s18.171,-80.2247 11,-87s-13.937,-7.63165 -13.937,-7.63165l-7.63931,-15.03426z"/>
  <path id="svg_11" fill="#ffb8b8" d="m111.534022,244.002727a10.49547,10.49547 0 0 0 7.899,-14.02168l50.6395,-42.96233l-18.55467,-5.60111l-46.35113,42.61819a10.55234,10.55234 0 0 0 6.3673,19.96693z"/>
  <path id="svg_12" fill="#2b2937" d="m240.139592,131.625197s9.9774,-20.43464 -13.90837,-16.944s-114.46472,96.40252 -114.46472,96.40252l15.15852,19.93067l91.544,-81.11205l21.67057,-18.27714z"/>
  <path id="svg_13" fill="#e6e6e6" d="m201.242222,99.963607l-94.70239,121.32594a10.08064,10.08064 0 0 0 1.74093,14.12657c21.99237,53.3988 87.78748,92.9964 165.1861,128.938a10.07423,10.07423 0 0 0 14.12658,-1.741l94.70239,-121.32594a10.07419,10.07419 0 0 0 -1.741,-14.12659c-64.952,-36.67587 -120.77823,-79.16815 -165.1861,-128.93795a10.0806,10.0806 0 0 0 -14.12651,1.74097z"/>
  <path id="svg_14" fill="#fff" d="m375.649022,235.526877l-28.82644,36.93038a107.91279,107.91279 0 0 1 -151.02282,18.612l-80.78474,-63.05752a5.73345,5.73345 0 0 1 -0.99832,-8.03743l91.51114,-117.23753a5.73348,5.73348 0 0 1 8.03933,-0.983c40.86527,52.76917 95.56689,93.83378 161.09216,125.74238a5.728,5.728 0 0 1 0.98969,8.03072z"/>
  <path id="svg_15" fill="#ccc" d="m199.353022,163.195977l-18.77341,24.05111a3.05106,3.05106 0 0 1 -4.81022,-3.75468l18.77338,-24.05111a3.05106,3.05106 0 1 1 4.81022,3.75468l0.00003,0z"/>
  <path id="svg_16" fill="#ccc" d="m234.566232,145.355557l-41.22784,52.81817a3.05106,3.05106 0 0 1 -4.81023,-3.75468l41.22786,-52.81816a3.05106,3.05106 0 1 1 4.81022,3.75467l-0.00001,0z"/>
  <circle id="svg_17" fill="#b2c6c5" r="20.09591" cy="213.35969" cx="164.25091"/>
  <path id="svg_18" fill="#ccc" d="m236.755152,187.099587l-50.63607,64.87132a3.07083,3.07083 0 0 1 -4.81022,-3.75468l50.63607,-64.87131a3.07083,3.07083 0 0 1 4.81022,3.75467z"/>
  <path id="svg_19" fill="#ccc" d="m269.603922,172.288387l-70.67559,90.54451a3.07083,3.07083 0 0 1 -4.81022,-3.75468l70.67558,-90.5445a3.07083,3.07083 0 0 1 4.81023,3.75467z"/>
  <path id="svg_20" fill="#ccc" d="m269.358522,212.548537l-50.63607,64.87132a3.07083,3.07083 0 0 1 -4.81022,-3.75468l50.63607,-64.87131a3.07083,3.07083 0 0 1 4.81022,3.75467z"/>
  <path id="svg_21" fill="#b2c6c5" d="m336.358522,220.548537l-50.63607,64.87132a3.07083,3.07083 0 0 1 -4.81022,-3.75468l50.63607,-64.87131a3.07083,3.07083 0 0 1 4.81022,3.75467z"/>
  <path id="svg_22" fill="#ccc" d="m302.207292,197.737317l-70.67559,90.54451a3.07083,3.07083 0 0 1 -4.81022,-3.75468l70.67559,-90.5445a3.07083,3.07083 0 0 1 4.81022,3.75467z"/>
  <path id="svg_23" fill="#2f2e41" d="m218.961322,50.243617a29.82287,29.82287 0 1 0 51.29533,-20.68523l-0.14314,-0.143c-0.2386,-0.25061 -0.4772,-0.50107 -0.72774,-0.73974a0.01167,0.01167 0 0 1 -0.01186,-0.01194c-0.31017,-0.28629 -0.62034,-0.57258 -0.94245,-0.84693a29.815,29.815 0 0 0 -49.47014,22.42686l0,-0.00002z"/>
  <circle id="svg_24" fill="#ffb8b8" r="25.00775" cy="57.14378" cx="256.13166"/>
  <path id="svg_25" fill="#2f2e41" d="m229.472022,63.808237c-2.44434,-11.01562 1.08886,-23.35693 8.792,-30.71093a28.19911,28.19911 0 0 1 29.44824,-5.708c0.62891,0.2539 1.25781,0.53174 1.875,0.82177l0.28711,0.13526l0,0.042c0.05566,0.03857 0.11133,0.0791 0.167,0.12012a0.95727,0.95727 0 0 0 0.41309,0.228a15.4171,15.4171 0 0 1 11.63379,4.8496c3.876,4.20557 5.70117,11.002 5.1416,19.13721l-0.01758,0.2544l-0.2168,0.13525a85.4635,85.4635 0 0 1 -9.11816,5.02734c-10.97852,5.16211 -21.001,6.11866 -30.63379,2.92041l-0.17676,-0.05859l-4.043,-6.77588c-0.83007,4.67334 -0.38671,20.17465 -5.14941,21.01449l-8.29883,-10.96517l-0.1035,-0.46728z"/>
  <path id="svg_26" fill="#2f2e41" d="m176.829022,85.582257c8.51008,-4.39247 -2.43482,-20.33442 -0.23553,-29.65523c2.19936,-9.32082 18.037,-12.31871 19.04593,-21.84222s3.38245,-19.346 9.6668,-26.5725c4.01568,-4.61773 9.93278,-8.02669 16.02491,-7.44829a14.40869,14.40869 0 0 1 12.62827,17.79208c1.73454,-2.45776 6.22341,-1.92509 7.33449,0.87052c0.98788,2.48528 -1.19437,5.49567 -3.76252,5.71556c-3.36046,4.15 -7.2086,8.55427 -12.47325,9.39143a5.6806,5.6806 0 0 1 -4.84076,-1.11733c-1.23856,-1.18942 -1.33489,-3.56739 0.13434,-4.45625c-6.61615,2.62814 -10.49509,9.78155 -11.47678,16.83259c-0.98162,7.051 0.3765,14.18523 1.27147,21.24777s1.28058,14.492 -1.48859,21.05046c-3.27178,7.74885 -10.80453,13.30263 -19.02519,15.0837s-16.97551,0.04034 -24.41659,-3.88148c-7.44108,-3.92212 -13.64622,-9.90941 -18.71523,-16.62188c8.7317,6.2002 20.81207,8.52296 30.32823,3.61107z"/>
  <path id="svg_27" fill="#2f2e41" d="m244.185552,55.527157c-8.74377,15.063 7.72065,40.4432 -8.87275,45.71354l1.367,-1.367c10.0162,-12.32036 -10.919,-38.397 -0.90274,-50.71737a30.10219,30.10219 0 0 0 3.97967,-5.91559"/>
  <path id="svg_28" fill="#b2c6c5" d="m230.639932,64.580317s7.408,-27.65168 39.16341,-34.03389c0.03575,-0.01179 0.15508,-0.05956 0.31017,-0.13106c0.09538,-0.0597 0.21471,-0.1194 0.334,-0.179c-0.35786,-0.20285 -0.70378,-0.38167 -1.06172,-0.56078a0.01167,0.01167 0 0 1 -0.01186,-0.01194c-0.31017,-0.28629 -0.62034,-0.57258 -0.94245,-0.84693l-0.90663,0.03583s-31.86272,-5.04597 -36.88492,35.72777z"/>
  <path id="svg_29" fill="#ccc" d="m0.000022,543.372777a1.18646,1.18646 0 0 0 1.19006,1.19l715.29,0a1.19,1.19 0 0 0 0,-2.38l-715.29006,0a1.18651,1.18651 0 0 0 -1.19,1.19z"/>
 </g>
</svg>

<motion.div
  className='soccc'
  variants={variants}
  initial='hidden'
  animate='visible'
  transition={transition}
>
  <motion.button
    className="round-buttonn"
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 1.9 }}
    onClick={handleCallButtonClick}
  >
    Получить консультацию
  </motion.button>

</motion.div>
        </div>
      
    </div>
  );
};