import React from 'react';
import './Perspectives.css';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export const Perspectives = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.7,
  });
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.4,
      },
    },
  };

  const flipUnitVariants = {
    hidden: {
      opacity: 0,
      y: 10,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  const cards = [
    {
      title: 'Полный спектр услуг',
      description: 'Мы предоставляем все необходимые услуги для вашего бизнеса. Если вопрос выходит за рамки нашей компетенции, мы привлекаем партнеров и предлагаем вам профессионалов в данной области.',
    },
    {
      title: 'Освобождение ресурсов',
      description: 'С нами вы и ваши сотрудники избавляетесь от необходимости самостоятельно изучать законодательство, посещать налоговые органы и проводить регулярные совещания. Мы берем эти обязанности на себя, экономя ваше время.',
    },
    {
      title: 'Удобство ',
      description: 'В любое время вы можете скачать любой необходимый документ прямо у себя в офисе. Все процессы организуются с учетом особенностей вашей деятельности, обеспечивая удобство и эффективность работы. Мы работаем с 1C для обеспечения безупречного качества обслуживания.',
    },
  ];

  return (
    <div className='mainn_per'>
      <p className="perspectives-title">
        <span className='perspectives-word'>Преимущества </span>
        <span className="perspectives-highlight">работы с нами </span>
      </p>
      <div className="perspectives-container" ref={ref}>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          className="perspectives-container"
        >
          {cards.map((card, index) => (
            <motion.div
              key={index}
              variants={flipUnitVariants}
              className="perspectives-card"
            >
              <div className="perspectives-card-number">{String(index + 1).padStart(2, '0')}</div>
              <div className="perspectives-card-content">
                <h2 className="perspectives-card-title">{card.title}</h2>
                <p className="perspectives-card-description">{card.description}</p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};